import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import TextListUI from './UI';
import Pager from '@mpfe/pager';
class TextList extends Component {
  render() {
    const firstmenu = this.props.location.pathname.split('/')[1];
    const secmenu = this.props.location.pathname.split('/')[2];
    const { pagerOption, onPageChange } = this.props;
    const options = { ...pagerOption, channelNo: secmenu };
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[secmenu] &&
              this.props.routes.routesMap[secmenu].channelName}
          </h3>
        </div>
        <SQueryList options={options} type="QUERY_LIST">
          {props => {
            return (
              <>
                <TextListUI
                  data={props.records}
                  firstmenu={firstmenu}
                  secmenu={secmenu}
                />
                <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  forcePage={pagerOption.currentPage}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page => {
                    onPageChange(page);
                  }}
                ></Pager>
              </>
            );
          }}
        </SQueryList>
      </>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(TextList);
