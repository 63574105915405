import React, { Component } from 'react';
import { SQueryOne, SQueryList } from '@mpfe/cskit';
import { withRouter } from 'react-router-dom';
import './index.scss';
import LookBigImg from '../LookBigImg';

//详情
class MainDetail extends Component {
  render() {
    const { params } = this.props.match;
    const artid = params.id || params.resource;
    return (
      <SQueryOne type="QUERY_ONE_BY_ID" options={{ id: artid }}>
        {props => {
          return <Detail data={props.data} {...props} />;
        }}
      </SQueryOne>
    );
  }
}
export default MainDetail;

@withRouter
class Detail extends Component {
  componentDidUpdate(prevProps) {
    let temp = document.querySelectorAll('video');
    if (temp.length > 0) {
      Array.prototype.slice.call(temp).forEach(item => {
        item.setAttribute('controls', true);
        item.setAttribute('preload', true);
        item.setAttribute('autoplay', true);
        item.setAttribute('controlslist', 'nodownload');
      });
    }

    let temp1 = document.querySelectorAll('audio');
    if (temp1.length > 0) {
      Array.prototype.slice.call(temp1).forEach(item => {
        item.setAttribute('controls', true);
        item.setAttribute('preload', true);
        item.setAttribute('autoplay', true);
        item.setAttribute('controlslist', 'nodownload');
      });
    }
  }
  down(href, title) {
    let aLink = document.createElement('a');
    aLink.href = href; //设置下载的图片链接
    aLink.download = title; //设置图片下载之后的名称
    aLink.target = '_blank'; //设置图片下载之后的名称
    aLink.click(); //触发点击事件
  }
  render() {
    // if (Object.keys(this.props.data).length === 0) return <></>;
    const {
      title,
      author,
      content,
      publishDate,
      source,
      visitCount,
      groupId,
      subTitle,
    } = this.props.data;
    const location = window.location;
    const isVideo = location.pathname.split('/')[2] === 'video';
    console.log('groupId', groupId, location);
    return (
      <>
        <div className="maindetail">
          <div className="title">
            <h1>
              {title}
              <br />
              {subTitle}
            </h1>
          </div>
          <div className="tip">
            <span>发布时间：{publishDate}</span>
            {author ? <span>作者：{author}</span> : ''}
            <span>文章来源：{source ? source : '武汉博物馆'}</span>
            <span>浏览次数：{visitCount}</span>
          </div>
          <div
            ref={video => (this._video = video)}
            className={`cont ${isVideo ? 'video-cont' : ''}`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
        {/* 当第一条附件为pdf文件时，用iframe展示pdf文件 */}
        {groupId && (
          <SQueryList
            options={{
              id: groupId,
              code: 'CMS_ARCHIVES_FILE',
              pageSize: '0',
            }}
            type="QUERY_FIELDLIST"
          >
            {props => {
              console.log('props.records', props.records);
              return (
                props.records.length > 0 && (
                  <>
                    {props.records[0].type === 'pdf' && (
                      <iframe
                        className="detail-iframe"
                        src={`/pdfjs/web/viewer.html?file=${props.records[0].filePath}`}
                        title={props.records[0].title}
                      />
                    )}
                    {props.records[0].type ===
                      ('jpg' || 'png' || 'bmp' || 'jpeg') && (
                      <div className="img-detail">
                        <div className="title-img">相关图片</div>
                        <LookBigImg data={props.records}></LookBigImg>
                      </div>
                    )}
                  </>
                )
              );
            }}
          </SQueryList>
        )}

        {/* 信息资讯，志愿者之家附件 */}
        {groupId &&
          (location.pathname.includes('/survey/snews') ||
            location.pathname.includes('/education/volunteer')) && (
            <SQueryList
              options={{
                id: groupId,
                code: 'CMS_ARCHIVES_FILE',
                pageSize: '0',
              }}
              type="QUERY_FIELDLIST"
            >
              {props => {
                return (
                  props.records.length > 0 &&
                  !(
                    props.records[0].type === 'pdf' &&
                    props.records.length === 1
                  ) && (
                    <div className="cont">
                      <div className="fujian">
                        <span>附件：</span>
                        {props.records.map((item, index) => {
                          if (item.type === 'pdf' && index === 0) {
                            return '';
                          } else {
                            return (
                              <div className="fj-tip" key={index}>
                                <span> {item.title}</span>
                                <div
                                  className="down"
                                  onClick={() => {
                                    this.down(item.filePath, item.title);
                                  }}
                                >
                                  下载
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  )
                );
              }}
            </SQueryList>
          )}
      </>
    );
  }
}
