import React, { Component } from 'react';
import { connect } from 'react-redux';
import CcpList from './Ccplist.js';
import '../index.scss';
class CcpItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  render() {
    const firstmenu = this.props.location.pathname.split('/')[1];
    const { pagerOption, onPageChange } = this.props;
    const options = { ...pagerOption, channelNo: firstmenu };
    return (
      <>
        <div className="ccp-listpage">
          <div className="main-title">
            <h3>
              {this.props.routes.routesMap[firstmenu] &&
                this.props.routes.routesMap[firstmenu].channelName}
            </h3>
          </div>
          <CcpList
            namespace={firstmenu}
            firstmenu="second"
            options={options}
            pagerOption={pagerOption}
            onPageChange={onPageChange}
            page={true}
          ></CcpList>
        </div>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(CcpItemList);
