import React from 'react';
import { Link, NavLink } from 'react-router-dom';
/**
 * 普通路由
 *
 * @class MyLink
 * @extends {React.Component}
 */
class MyLink extends React.Component {
  constructor(props) {
    super(props);
    this.clickHandle = this.clickHandle.bind(this);
  }

  /**
   * e 点击a标签 阻止默认事件
   * 执行回调
   * @param {*} e
   * @memberof MyLink
   */
  clickHandle(e) {
    const { handleClick } = this.props;
    e.preventDefault();
    if (typeof handleClick === 'function') {
      handleClick();
    }
  }
  render() {
    const { linkUrl, handleClick, children, ...rest } = this.props;

    if (Boolean(handleClick)) {
      return (
        <a href="true" onClick={this.clickHandle}>
          {children}
        </a>
      );
    } else {
      return Boolean(linkUrl) === false ? (
        <Link {...rest}>{children}</Link>
      ) : (
        <a href={linkUrl} {...rest} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }
  }
}

/**
 * 导航的路由
 *
 * @export
 * @class MyNavLink
 * @extends {React.Component}
 */
export class MyNavLink extends React.Component {
  render() {
    const { linkUrl, children, ...rest } = this.props;

    return Boolean(linkUrl) === false ? (
      <NavLink {...rest}>{children}</NavLink>
    ) : (
      <a href={linkUrl} {...rest}>
        {children}
      </a>
    );
  }
}

export default MyLink;
