import React, { Component } from 'react';
import MyLink from '../../../components/MyLink';
export default class Selectbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
    };
    this.handelClick = this.handelClick.bind(this);
  }

  render() {
    const { data } = this.props;
    return (
      <>
        <div className="selectTitle" onClick={this.handelClick}>
          <p>友情链接</p>
          <div className={this.state.flag ? 'downlogo active' : 'downlogo'}>
            >
          </div>
          <ul className={this.state.flag ? 'selectList active' : 'selectList'}>
            {data &&
              data.map((item, index) => {
                return (
                  <li key={index}>
                    <MyLink linkUrl={item.linkUrl} to={''}>
                      <h5>{item.title}</h5>
                    </MyLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </>
    );
  }

  handelClick() {
    this.setState({
      flag: !this.state.flag,
    });
  }
}
