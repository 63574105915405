import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import MyLink from '../../../components/MyLink';
import './index.scss';
import { HtmltoStr } from '../../../../utils';
class Stydy extends Component {
  render() {
    const firmenu = this.props.location.pathname.split('/')[1];
    return (
      <>
        <div className="main-title" id="journal">
          <h3>
            {this.props.routes.routesMap[firmenu] &&
              this.props.routes.routesMap[firmenu].subChannels[0].channelName}
          </h3>
        </div>
        <div className="musintro journal">
          <div className="cont">
            {this.props.routes.routesMap[firmenu] && (
              <>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.props.routes.routesMap[firmenu].subChannels[0]
                      .description,
                  }}
                ></p>
                <Link
                  className="more"
                  to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[0].channelNo}`}
                >
                  查看详情
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="research-sec">
          <div className="first">
            <div className="main-title" id="colresearch">
              <h3>
                {this.props.routes.routesMap[firmenu] &&
                  this.props.routes.routesMap[firmenu].subChannels[1]
                    .channelName}
              </h3>
            </div>
            {this.props.routes.routesMap[firmenu] && (
              <SQueryList
                type="QUERY_LIST"
                options={{
                  channelNo: this.props.routes.routesMap[firmenu].subChannels[1]
                    .channelNo,
                  pageSize: 4,
                }}
              >
                {props => {
                  return (
                    <div className="research-list">
                      <NavLink
                        className="more"
                        to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[1].channelNo}`}
                      >
                        查看更多+
                      </NavLink>
                      <ul>
                        {props.records.map(item => {
                          return (
                            <li key={item.id}>
                              <MyLink
                                linkUrl={item.linkUrl}
                                to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[1].channelNo}/${item.id}`}
                              >
                                <p className="time">
                                  {item.publishDate.replace(/-/g, '/')}
                                </p>
                                <h5 className="title">{item.title}</h5>
                              </MyLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }}
              </SQueryList>
            )}
          </div>
          <div className="sec">
            <div className="main-title" id="whwenbo">
              <h3>
                {this.props.routes.routesMap[firmenu] &&
                  this.props.routes.routesMap[firmenu].subChannels[2]
                    .channelName}
              </h3>
            </div>
            {this.props.routes.routesMap[firmenu] && (
              <SQueryList
                type="QUERY_LIST"
                options={{
                  channelNo: this.props.routes.routesMap[firmenu].subChannels[2]
                    .channelNo,
                  pageSize: 4,
                }}
              >
                {props => {
                  return (
                    <div className="research-list">
                      <NavLink
                        className="more"
                        to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[2].channelNo}`}
                      >
                        查看更多+
                      </NavLink>
                      <ul>
                        {props.records.map((item, index) => {
                          return (
                            <li key={index}>
                              <MyLink
                                className={item.flag === 'a' ? `top` : ''}
                                linkUrl={item.linkUrl}
                                to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[2].channelNo}/${item.id}`}
                              >
                                <p className="time">
                                  {item.publishDate.replace(/-/g, '/')}
                                </p>
                                <h5 className="title">
                                  <span>{HtmltoStr(item.title, 23)}</span>
                                </h5>
                              </MyLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }}
              </SQueryList>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(Stydy);
