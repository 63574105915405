import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import MyLink from '../../../components/MyLink';
// import { HtmltoStr } from '../../../../utils';
import './index.scss';
import Slider from 'react-slick';
class Stydy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }
  handleEnter = index => {
    this.setState({
      index,
    });
  };
  settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    // speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    autoplaySpeed: 4000,
  };
  updateSetting(records) {
    const titles = records.map(item => item.title.split('——')[0]);
    const dot = {
      appendDots: dots => (
        <div className="dots">
          <ul className="dotsL"> {dots} </ul>
        </div>
      ),
      customPaging: i => <h3>{titles[i]}</h3>,
    };
    return Object.assign(this.settings, dot);
  }
  render() {
    const firmenu = this.props.location.pathname.split('/')[1];
    const options_baseexhibition = {
      pageSize: '4',
      channelNo: 'baseexhibition',
      languageType: sessionStorage.getItem('language'),
    };
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      //   speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: true,
      pauseOnDotsHover: true,
      autoplaySpeed: 6000,
      fade: true,
    };
    return (
      <>
        <div className="main-title" id="baseexhibition">
          {this.props.routes.routesMap[firmenu] && (
            <>
              <h3>
                {this.props.routes.routesMap[firmenu] &&
                  this.props.routes.routesMap[firmenu].subChannels[0]
                    .channelName}
              </h3>
              <Link
                to={`${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[0].channelNo}`}
                className="more baseexh-more"
              >
                更多 +
              </Link>
            </>
          )}
        </div>
        <div className="musintro baseexhibition">
          <div className="cont">
            {this.props.routes.routesMap[firmenu] && (
              <>
                <SQueryList options={options_baseexhibition} type="QUERY_BASE">
                  {props => {
                    return (
                      <Slider
                        {...this.updateSetting(props.records)}
                        key={Math.random()}
                      >
                        {props.records.map(item => {
                          return (
                            <Link
                              to={`${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[0].channelNo}/${item.id}`}
                              key={item.id}
                              className="item"
                            >
                              <img src={item.imgSrc} alt={item.title} />
                            </Link>
                          );
                        })}
                      </Slider>
                    );
                  }}
                </SQueryList>
              </>
            )}
          </div>
        </div>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[firmenu] &&
              this.props.routes.routesMap[firmenu].subChannels[1].channelName}
          </h3>
        </div>
        <div className="musintro tempexh">
          <div className="cont">
            {this.props.routes.routesMap[firmenu] && (
              <>
                <div className="img-banner">
                  <SQueryList
                    type="QUERY_TEMP"
                    options={{
                      channelNo: this.props.routes.routesMap[firmenu]
                        .subChannels[1].channelNo,
                      pageSize: 5,
                      exhtype: 'cms0701',
                      isRecommend: true,
                    }}
                  >
                    {props => {
                      return (
                        <Slider {...settings} key={Math.random()}>
                          {props.records.map(item => {
                            return (
                              <div className="item" key={item.id}>
                                <MyLink
                                  linkUrl={item.linkUrl}
                                  to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[1].channelNo}/${item.id}`}
                                >
                                  <img src={item.imgSrc} alt={item.title} />
                                </MyLink>
                              </div>
                            );
                          })}
                        </Slider>
                      );
                    }}
                  </SQueryList>
                </div>
                <SQueryList
                  type="QUERY_TEMP"
                  options={{
                    channelNo: this.props.routes.routesMap[firmenu]
                      .subChannels[1].channelNo,
                    pageSize: 5,
                    exhtype: 'cms0701',
                  }}
                >
                  {props => {
                    return (
                      <div className="temp-list">
                        <ul className="title-list">
                          <li>展览名称</li>
                          <li>展览地点</li>
                          <li>展览时间</li>
                        </ul>
                        <ul className="content-list">
                          {props.records.map((item, index) => {
                            return (
                              <li key={item.id}>
                                <MyLink
                                  linkUrl={item.linkUrl}
                                  to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[1].channelNo}/${item.id}`}
                                >
                                  <p className="title">{item.title}</p>
                                  <p className="palce">{item.place}</p>
                                  <p className="time">{item.exhDate}</p>
                                </MyLink>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="more-box">
                          <NavLink
                            className="more"
                            to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[1].channelNo}`}
                          >
                            查看更多
                          </NavLink>
                        </div>
                      </div>
                    );
                  }}
                </SQueryList>
              </>
            )}
          </div>
        </div>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[firmenu] &&
              this.props.routes.routesMap[firmenu].subChannels[2].channelName}
          </h3>
        </div>
        <div className="musintro specialexh">
          <div className="cont">
            {this.props.routes.routesMap[firmenu] && (
              <>
                <SQueryList
                  type="QUERY_TEMP"
                  options={{
                    channelNo: this.props.routes.routesMap[firmenu]
                      .subChannels[2].channelNo,
                    pageSize: 3,
                    exhtype: 'cms0702',
                  }}
                >
                  {props => {
                    return (
                      <>
                        <ul className="special-img">
                          {props.records.map((item, index) => {
                            return (
                              <li
                                key={item.id}
                                className={
                                  this.state.index === index ? 'active' : null
                                }
                              >
                                <div className="imgstyle">
                                  <div
                                    className="img"
                                    style={{
                                      backgroundImage: item.imgSrc
                                        ? `url(${item.imgSrc})`
                                        : null,
                                    }}
                                  />
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="special-list">
                          <ul>
                            {props.records.map((item, index) => {
                              return (
                                <li
                                  key={item.id}
                                  className={
                                    this.state.index === index ? 'active' : null
                                  }
                                  onMouseEnter={() => this.handleEnter(index)}
                                >
                                  <MyLink
                                    linkUrl={item.linkUrl}
                                    to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[2].channelNo}/${item.id}`}
                                  >
                                    <h5 className="title">{item.title}</h5>
                                    <p className="time">{item.exhDate}</p>
                                  </MyLink>
                                </li>
                              );
                            })}
                          </ul>
                          <NavLink
                            className="more"
                            to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[2].channelNo}`}
                          >
                            查看更多
                          </NavLink>
                        </div>
                      </>
                    );
                  }}
                </SQueryList>
              </>
            )}
          </div>
        </div>
        <div className="main-title" id="online">
          <h3>
            {this.props.routes.routesMap[firmenu] &&
              this.props.routes.routesMap[firmenu].subChannels[3].channelName}
          </h3>
        </div>
        <div className="musintro online">
          {this.props.routes.routesMap[firmenu] && (
            <Link
              className="more"
              to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[3].channelNo}`}
            >
              点击进入
            </Link>
          )}
        </div>
      </>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(Stydy);
