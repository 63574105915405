import React, { Component } from 'react';
import Share from '../../../components/Share';

export default class DetailShare extends Component {
  render() {
    return (
      <div className="detail-share">
        <i className="share"></i>
        <span>分享：</span>
        <Share
          url={this.props.url}
          title={this.props.title}
          description={this.props.description}
          disabled={[
            'google',
            'twitter',
            'facebook',
            'douban',
            'linkedin',
            'tencent',
          ]}
          weiboTitle="这个标题只有的分享到微博时有用，其它标题为全局标题"
          isShare={false}
        />
      </div>
    );
  }
}
