import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import PropTypes from 'prop-types';
import Pager from '@mpfe/pager';
import LookBigImg from '../../../common/LookBigImg/index';
import Attention from '../../../../../assets/images/desktop/ccp/attention.png';
export default class CcpList extends Component {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    firstmenu: PropTypes.string.isRequired,
  };
  render() {
    const pager = this.props.page;
    return (
      <>
        <SQueryList options={this.props.options} type="QUERY_LIST">
          {props => {
            return (
              <>
                {props.records.length !== 0 ? (
                  <LookBigImg data={props.records}></LookBigImg>
                ) : null}

                {/* <ul className="common-imglist">
                  {props.records.map(item => {
                    return (
                      <li key={item.id}>
                        <MyLink
                          linkUrl={item.linkUrl}
                          to={`${linkurl}/${item.id}`}
                        >
                          <div className="imgstyle">
                            <div
                              className="img"
                              style={{
                                backgroundImage: item.imgSrc
                                  ? `url(${item.imgSrc})`
                                  : null,
                              }}
                            />
                          </div>
                          <div className="cont">
                            <h3>{item.title}</h3>
                            <p className="size">
                              {deleteHtmlTag(item.subTitle)}
                            </p>
                          </div>
                        </MyLink>
                      </li>
                    );
                  })}
                </ul> */}

                {pager ? (
                  <div className="ccp-page">
                    <Pager
                      containerClassName="mypager"
                      pagerClassName="sw-pages"
                      pageCount={props.pageCount}
                      forcePage={this.props.pagerOption.currentPage}
                      hrefCreator={page => {
                        return `?page=${page}`;
                      }}
                      onPageChange={page => {
                        this.props.onPageChange(page);
                      }}
                    ></Pager>
                  </div>
                ) : null}
                <p className="attendtion">
                  <img src={Attention} alt=""></img>
                  以上文创产品，请赴本馆二楼文创店购买。
                </p>
              </>
            );
          }}
        </SQueryList>
      </>
    );
  }
}
