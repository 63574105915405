import React from 'react';
// import { Link } from 'react-router-dom';
import MyLink from '../../../../components/MyLink';
const JournalListUI = props => {
  return (
    <>
      <ul className="journallist">
        {props.data.map(item => {
          return (
            <li key={item.id}>
              <MyLink
                linkUrl={item.linkUrl}
                to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
              >
                <div className="imgstyle">
                  <div
                    className="img"
                    style={{
                      backgroundImage: item.imgSrc
                        ? `url(${item.imgSrc})`
                        : null,
                    }}
                  />
                  <div className="shade">
                    <div
                      className="info"
                      dangerouslySetInnerHTML={{ __html: item.abstract }}
                    />
                    <span>More</span>
                  </div>
                </div>
                <div className="cont">
                  <h3>{item.title}</h3>
                </div>
              </MyLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default JournalListUI;
