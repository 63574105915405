import http from './http';
import {
  ChannelFilter,
  ListFilter,
  DetailFilter,
  ExhListFilter,
  ExhDetailFilter,
  BaseListFilter,
  BaseDetailFilter,
  FileFilter,
  ColListFilter,
  ColDetailFilter,
  SearchListFilter,
  SearchCollectionFilter,
} from './filter';
import { id } from './aes';
const GATEWAY = window.GATEWAY;
export function getRoutes() {
  return new Promise((resolve, reject) => {
    const language = sessionStorage.getItem('language')
      ? sessionStorage.getItem('language')
      : 'cn';
    http
      .get(`/japi/${GATEWAY}-cms/api/queryAllChannel/${language}`)
      .then(res => {
        if (res.data.code !== 200) {
          window.location.href = '/errpage';
          return;
        }
        const r = ChannelFilter(res.data.data.records);
        resolve({
          records: r.list,
          routesMap: r.channelList,
        });
      })
      .catch(err => {
        window.location.href = '/errpage';
        reject(err);
      });
  });
}

// 获取文章列表 namespace:栏目编号
export function getList(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-cms/api/queryArchivesList`, {
        entity: {
          channelNo: option.channelNo,
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: ListFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}

// 获取临时展览列表 分页信息中增加type字段，1为现在，2为过去，不传为全体
export function getTempExhList(option) {
  let type = '';
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-cms/api/queryExhibitionTempList`, {
        entity: {
          isRecommend: option.isRecommend,
          languageType: option.languageType,
          type: option.exhtype, //特展 赴外展
          //startTime: option.startTime,
        },
        param: {
          type: type,
          pageSize: option.pageSize,
          pageNum: option.currentPage,
          str: option.startTime,
        },
      })
      .then(res => {
        resolve({
          records: ExhListFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}

// 获取临时展览详情
export function getTempExhDetail(option) {
  return new Promise((resolve, reject) => {
    http
      .get(`/japi/${GATEWAY}-cms/api/queryExhibitionTempById/${option.id}`, {})
      .then(res => {
        resolve(ExhDetailFilter(res.data.data));
      })
      .catch(err => reject(err));
  });
}

// 获取临时展览相关藏品
export function getColListByExhId(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-cms/api/queryExhibitListByTemp`, {
        entity: {
          groupId: option.groupId ? option.groupId : '',
          languageType: option.languageType ? option.languageType : 'CN',
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: ColListFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 获取常展列表
export function getBaseExhList(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-cms/api/queryExhibitionBaseList`, {
        entity: {
          isRecommend: option.isRecommend,
          languageType: null,
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: BaseListFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}

// 获取常展详情
export function getBaseExhDetail(option) {
  return new Promise((resolve, reject) => {
    http
      .get(`/japi/${GATEWAY}-cms/api/queryExhibitionBaseById/${option.id}`, {})
      .then(res => {
        resolve(BaseDetailFilter(res.data.data));
      })
      .catch(err => reject(err));
  });
}

// 获取藏品列表 namespace:藏品类型
export function getColList(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-cms/api/queryExhibitList`, {
        entity: {
          exhibitType: option.channelNo !== 'all' ? option.channelNo : '',
          exhibitName: option.exhibitName,
          exhibitLevel: option.exhibitLevel ? option.exhibitLevel : '',
          languageType:
            option.languageType !== 'hw' ? option.languageType : 'kr',
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: ColListFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}

// 获取藏品详情
export function getColDetail() {
  return resource => {
    return new Promise((resolve, reject) => {
      http
        .get(`/japi/${GATEWAY}-cms/api/queryExhibitById/${resource}`, {})
        .then(res => {
          resolve(ColDetailFilter(res.data.data));
        })
        .catch(err => reject(err));
    });
  };
}

//注入到Preset中的provider  通过type 返回不同的数据
export function contextProvider(type) {
  switch (type) {
    case 'QUERY_ONE_BY_ID':
      return resource => {
        return new Promise((resolve, reject) => {
          http
            .get(`/japi/${GATEWAY}-cms/api/queryArchivesById/${resource.id}`)
            .then(res => {
              resolve(DetailFilter(res.data.data));
            })
            .catch(err => reject(err));
        });
      };
    case 'QUERY_ONE_BY_NO':
      return option => {
        return new Promise((resolve, reject) => {
          http
            .get(
              `/japi/${GATEWAY}-cms/api/queryArchivesByNo/${option.channelNo}`,
            )
            .then(res => {
              resolve(DetailFilter(res.data.data));
            })
            .catch(err => reject(err));
        });
      };
    case 'LIKE_ONE_BY_ID':
      return resource => {
        return new Promise((resolve, reject) => {
          http
            .get(`/japi/${GATEWAY}-cms/api/doStarByArcId/${resource}`)
            .then(res => {
              resolve(res.data.data);
            })
            .catch(err => reject(err));
        });
      };
    case 'QUERY_LIST':
      return option => getList(option);
    case 'QUERY_TEMP':
      return option => getTempExhList(option);
    case 'QUERY_TEMPDET':
      return option => getTempExhDetail(option);
    case 'QUERY_BASE':
      return option => getBaseExhList(option);
    case 'QUERY_BASEDET':
      return option => getBaseExhDetail(option);
    case 'QUERY_COL':
      return option => getColList(option);
    case 'QUERY_FIELDLIST':
      return option => getFileList(option);
    case 'QUERY_COLLIST_BYEXHID':
      return option => getColListByExhId(option);
    case 'QUERY_SEARCHLIST':
      return option => getSearchList(option);
    case 'QUERY_VIDEO':
      return option => getFile(option);
    case 'QUERY_Dictionary':
      return option => queryDictionary(option);
    case 'search_Collection':
      return option => searchCollection(option);
    default:
      return Promise.resolve();
  }
}

// 获取附件
export function getFileList(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-file/refFileObject/queryPageList`, {
        entity: {
          objectId: option.id, //关联id
          // objectId: 'eed894d6abbbc238eed9e7d4831da45a', //关联id
          code: option.code ? option.code : '', //文件类型
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: FileFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}
// 获取视频附件
export function getFile(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-file/refFileObject/queryById`, {
        entity: {
          objectId: option.id, //关联id
          // objectId: 'eed894d6abbbc238eed9e7d4831da45a', //关联id
          code: option.code ? option.code : '', //文件类型
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: FileFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}
// 全文检索
export function getSearchList(option) {
  return new Promise((resolve, reject) => {
    http
      .post(
        `/japi/${GATEWAY}-cms/api/queryAllPageList?searchCode=${option.text}`,
        {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      )
      .then(res => {
        resolve({
          records: SearchListFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.total,
        });
      })
      .catch(err => reject(err));
  });
}
// 获取字典项
export function queryDictionary(option) {
  return new Promise((resolve, reject) => {
    http
      .post(
        `/japi/${GATEWAY}-cms/api/queryDictionaryByCode?dictionaryCode=${option.namespace}&appId=${id}`,
      )
      .then(res => {
        resolve({
          records: res.data.data,
        });
      })
      .catch(err => reject(err));
  });
}

export function searchCollection(option) {
  return new Promise((resolve, reject) => {
    http
      .post(`/japi/${GATEWAY}-cms/api/queryExhibitList`, {
        entity: {
          exhibitName: option.name,
          age: option.age,
          exhibitType: option.type === 'treasure' ? '' : option.type,
          exhibitLevel: option.type === 'treasure' ? 'CMS0900' : '',
          isOwn: true,
        },
        param: {
          pageSize: option.pageSize,
          pageNum: option.currentPage,
        },
      })
      .then(res => {
        resolve({
          records: SearchCollectionFilter(res.data.data.records),
          pageCount: res.data.data.pages,
          currentPage: res.data.data.current,
          totalNum: res.data.data.pages.total,
        });
      })
      .catch(err => reject(err));
  });
}
