import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import GoBack from '../GoBack';
import './index.scss';
import cls from 'classnames';
import { connect } from 'react-redux';
import { getLanguage, HyphenLang, searchLang } from '../../../utils/cc';

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routesMap: [],
      lang: getLanguage(),
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const pathnameArray = nextProps.location.pathname
      .split('/')
      .filter(item => item !== '');
    pathnameArray.unshift('');
    const { routesMap } = nextProps;
    let matchedRoutes = [];
    const lang = prevState.lang;
    //请求到数据
    if (Object.keys(routesMap).length > 0) {
      pathnameArray.forEach((item, index) => {
        const enhanceItem = `${item}${HyphenLang}`;
        item === ''
          ? matchedRoutes.push({
              path: lang ? '/index' : '/',
              pathname: routesMap[`home${HyphenLang}`].channelName,
              channelNo: routesMap[`home${HyphenLang}`].channelNo,
            })
          : routesMap[enhanceItem]
          ? matchedRoutes.push({
              path:
                lang && index === 1
                  ? `${routesMap[enhanceItem].pathname}/${routesMap[enhanceItem].firstSubChannel}`
                  : //    routesMap[enhanceItem].subChannel
                    //   ? `${routesMap[enhanceItem].pathname}/${routesMap[enhanceItem].subChannel}`
                    //   :
                    `${routesMap[enhanceItem].pathname}`,
              pathname: routesMap[enhanceItem].channelName,
              channelNo: routesMap[enhanceItem].channelNo,
            })
          : matchedRoutes.push();
        //   : matchedRoutes.push({
        //       path: '',
        //       pathname: '详情',
        //       channelNo: 'detail',
        //     });
      });

      return {
        routesMap: matchedRoutes,
      };
    }
    return null;
  }
  render() {
    const { routesMap } = this.state;
    return (
      <ul className={cls('breadcrumb', { mobile: this.props.isMobile })}>
        {routesMap.map((r, i) => (
          <li key={r.channelNo}>
            {i !== 0 && <i>></i>}
            <Link to={r.path !== '' ? `${r.path}${searchLang}` : ''}>
              {r.pathname}
            </Link>
          </li>
        ))}
        <GoBack />
      </ul>
    );
  }
}
const mapStateToProps = state => ({
  routesMap: state.toproutes.routesMap,
});
export default withRouter(connect(mapStateToProps)(Breadcrumb));
