import React, { Component } from 'react';
import { SQueryOne, SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
// import { getColDetail } from '../../../../../mpa/dataProvider';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';
import './Detail.scss';
import LookBigImg from '../../../common/LookBigImg/index';
class ColDetail extends Component {
  viewer = null;
  componentDidMount() {
    if (true) {
      this.viewer = new Viewer(document.getElementById('photoviewer1'), {
        inline: false,
        url: 'data-original',
        title: false,
      });
    }
  }
  componentWillUnmount() {
    this.viewer && this.viewer.destroy();
  }
  render() {
    // const id = this.props.location.pathname.split('/')[3];
    //  const options = { ...pagerOption, channelNo: secmenu };
    const { params } = this.props.match;
    const artid = params.id || params.resource;
    return (
      <>
        <div className="stydydetail">
          <div id="photoviewer1">
            <SQueryOne type="QUERY_ONE_BY_ID" options={{ id: artid }}>
              {props => {
                const item = props.data;
                return (
                  <div className="topbox">
                    <div className="img c imgstyle use-img ">
                      <img
                        style={{ objectFit: 'cover' }}
                        src={item.imgSrc ? item.imgSrc : null}
                        alt={item.title ? item.title : null}
                        data-original={
                          item.appimgSrc
                            ? item.appimgSrc
                            : item.imgSrc
                            ? item.imgSrc
                            : null
                        }
                      />
                    </div>
                    <div className="maindetail">
                      <div className="title">
                        <h1>{item.title}</h1>
                      </div>
                      <div
                        className="cont"
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              }}
            </SQueryOne>
          </div>
        </div>
        <div className="img-detail">
          <SQueryOne type="QUERY_ONE_BY_ID" options={{ id: artid }}>
            {props => {
              const item = props.data.groupId ? props.data : null;
              return (
                item && (
                  <SQueryList
                    options={{
                      id: item.groupId,
                      code: 'CMS_ARCHIVES_FILE',
                      pageSize: '0',
                    }}
                    type="QUERY_FIELDLIST"
                  >
                    {props => {
                      return (
                        props.records.length > 0 && (
                          <>
                            <div className="title-img">相关图片</div>
                            <LookBigImg data={props.records}></LookBigImg>
                          </>
                        )
                      );
                    }}
                  </SQueryList>
                )
              );
            }}
          </SQueryOne>
        </div>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(ColDetail);
