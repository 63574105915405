import React, { Component } from 'react';
import { SQueryOne, SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LookBigImg from '../../../common/LookBigImg/index';
class BaseExhDetail extends Component {
  render() {
    const id = this.props.match.params.resource;
    // let place, etime, einfor, eintro, escen, ecol;

    return (
      <>
        <div className="baseexhdetail">
          <SQueryOne options={{ id: id }} type="QUERY_BASEDET">
            {props => {
              const item = props.data;
              //const { title, content, publishDate, source, visitCount } = this.props.data;
              const groupId = item.groupId;
              return (
                <>
                  <div className="maindetail">
                    <div className="title">
                      <h1>{item.title}</h1>
                    </div>
                    <div
                      className="cont"
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </div>
                  <div className="img-detail">
                    {groupId && (
                      <>
                        <SQueryList
                          options={{
                            id: groupId,
                            code: 'CMS_EXHIBIT_BASE_FILE',
                            pageSize: '0',
                          }}
                          type="QUERY_FIELDLIST"
                        >
                          {props => {
                            return (
                              props.records.length >= 0 && (
                                <>
                                  <div className="title-img">展陈风采</div>
                                  <LookBigImg data={props.records}></LookBigImg>
                                </>
                              )
                            );
                          }}
                        </SQueryList>
                        <SQueryList
                          options={{
                            groupId,
                            pageSize: '0',
                          }}
                          type="QUERY_COLLIST_BYEXHID"
                        >
                          {props => {
                            return (
                              props.records.length > 0 && (
                                <>
                                  <div className="title-img">本展必看</div>
                                  <ul className="common-imglist">
                                    {props.records.map((item, key) => {
                                      return (
                                        <li key={key}>
                                          <Link
                                            to={`/exhibition/collect/${item.id}`}
                                          >
                                            <div className="imgstyle imgscale imgfull">
                                              <div
                                                className="img"
                                                style={{
                                                  backgroundImage: item.imgSrc
                                                    ? `url(${item.imgSrc})`
                                                    : null,
                                                }}
                                              />
                                            </div>
                                            <div className="cont">
                                              <h3>{item.title}</h3>
                                              <p>{item.age}</p>
                                            </div>
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </>
                              )
                            );
                          }}
                        </SQueryList>
                      </>
                    )}
                  </div>
                </>
              );
            }}
          </SQueryOne>

          {/*    <QueryList
            options={{
              id,
              code: 'CMS_EXHIBIT_TEMP_FILE',
              pageSize: 8,
            }}
            namespace={'filelist'}
          >
            {props => {
              return (
                <>
                  {props.records.length !== 0 ? (
                    <>
                      <div className="title">{escen}</div>
                      <ul className="common-imglist">
                        {props.records.map(item => {
                          return (
                            <li key={item.id}>
                              <div className="imgstyle imgscale">
                                <div
                                  className="img"
                                  style={{
                                    backgroundImage: item.filePath
                                      ? `url(${item.filePath})`
                                      : null,
                                  }}
                                />
                              </div>
                              <div className="cont">
                                <h3>{item.title}</h3>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    ''
                  )}
                </>
              );
            }}
          </QueryList>
          <QueryList
            options={{
              id,
              pageSize: 8,
            }}
            namespace={'exh_collist'}
          >
            {props => {
              return (
                <>
                  {props.records.length !== 0 ? (
                    <>
                      <div className="title">{ecol}</div>
                      <ul className="common-imglist">
                        {props.records.map(item => {
                          return (
                            <li>
                              <Link to={''}>
                                <div className="imgstyle imgscale">
                                  <div
                                    className="img"
                                    style={{
                                      backgroundImage: item.imgSrc
                                        ? `url(${item.imgSrc})`
                                        : null,
                                    }}
                                  />
                                </div>
                                <div className="cont">
                                  <h3>{item.title}</h3>
                                  <p>{item.age}</p>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    ''
                  )}
                </>
              );
            }}
          </QueryList>
       */}
        </div>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(BaseExhDetail);
