import React from 'react';
// import { Link } from 'react-router-dom';
import MyLink from '../../../../components/MyLink';
// import { HtmltoStr } from '@/utils';
const TempExhListUI = props => {
  return (
    <ul className="tempexhlist">
      {props.data.map(item => {
        // return props.secmenu === 'tempexhibition' ? (
        //   <li key={item.id} className="lite">
        //     <Link
        //       to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
        //       className="lia"
        //     >
        //       <div className="imgstyle">
        //         <div
        //           className="img"
        //           style={{
        //             backgroundImage: item.imgSrc ? `url(${item.imgSrc})` : null,
        //           }}
        //         />
        //       </div>
        //       <div className="cont">
        //         <h3>{item.title}</h3>
        //         <div className="abstract">
        //           <p>展览时间：{item.exhDate}</p>
        //           <p>展览地点：{item.place}</p>
        //           <p>展览内容：{HtmltoStr(item.content, 80)}</p>
        //         </div>
        //       </div>
        //     </Link>
        //   </li>
        // ) : (
        return props.secmenu === 'virtualexhibitionhall' ? (
          <li key={item.id} className="lisp">
            <a
              href={item.linkUrl}
              className="lia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imgstyle">
                <div
                  className="img"
                  style={{
                    backgroundImage: item.imgSrc ? `url(${item.imgSrc})` : null,
                  }}
                />
              </div>
              <div className="cont">
                <h3>{item.title}</h3>
              </div>
            </a>
          </li>
        ) : (
          <li key={item.id} className="lisp">
            <MyLink
              linkUrl={item.linkUrl}
              to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
              className="lia"
            >
              <div className="imgstyle">
                <div
                  className="img"
                  style={{
                    backgroundImage: item.imgSrc ? `url(${item.imgSrc})` : null,
                  }}
                />
              </div>
              <div className="cont">
                <h3>{item.title}</h3>
                <div className="abstract">
                  <p>展览时间：{item.exhDate}</p>
                  <p>展览地点：{item.place}</p>
                </div>
              </div>
            </MyLink>
          </li>
        );

        // );
      })}
    </ul>
  );
};
export default TempExhListUI;
