import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SQueryOne, SQueryList } from '@mpfe/cskit';
import { deleteHtmlTag, HtmltoStr } from '@/utils';
import { Link } from 'react-router-dom';
import MyLink from '../../../components/MyLink';
import './style.scss';
import { getDateofMonthandDay } from '../../../../utils';
import { NavHashLink as NavLink } from 'react-router-hash-link';
class Survey extends PureComponent {
  state = {
    introductionOptions: {
      channelNo: `introduction`,
    },
    openinfoOptions: {
      channelNo: `openinfo`,
    },
    snewsOptions: {
      channelNo: `snews`,
      pageSize: 5,
      currentPage: 1,
    },
    time: [
      { id: 0, time: window.opentime, title: '入馆' },
      { id: 1, time: window.stoptime, title: '停止领票入场' },
      { id: 2, time: window.closetime, title: '闭馆' },
    ],
    aList: [
      {
        id: 0,
        classname: 'iconfont iconjiaotong',
        title: '交通',
        linkUrl: '/survey/openinfo',
        name: 'traffic',
      },
      {
        id: 1,
        classname: 'iconfont iconditu',
        title: '导览',
        linkUrl: '/survey/openinfo',
        name: 'guide',
      },
      {
        id: 2,
        classname: 'iconfont iconzhuyi',
        title: '注意',
        linkUrl: '/survey/openinfo',
        name: 'intention',
      },
      //   {
      //     id: 3,
      //     classname: 'iconfont iconyuyue',
      //     title: '预约',
      //     linkUrl: '/survey/openinfo',
      //     name: 'order',
      //   },
    ],
    active: 0,
  };
  handleMouseEnter = index => {
    this.setState({ active: index });
  };
  handleClick(e, link, id) {
    e.preventDefault();
    this.props.history.push({
      pathname: link,
      search: `?id=${id}`,
    });
  }
  render() {
    const { routesMap } = this.props;
    const {
      introductionOptions,
      openinfoOptions,
      snewsOptions,
      time,
      aList,
      active,
    } = this.state;
    return (
      <>
        <div className="main-title">
          <h3>
            {routesMap[introductionOptions.channelNo] &&
              routesMap[introductionOptions.channelNo].channelName}
          </h3>
        </div>
        <div className="introduction-i">
          <SQueryOne type="QUERY_ONE_BY_NO" options={introductionOptions}>
            {props => {
              return (
                <div className="introduction-cont">
                  <h3 className="title">{props.data.title}</h3>
                  <p className="cont">{deleteHtmlTag(props.data.abstract)}</p>
                  <Link className="viewmore" to={`/survey/introduction`}>
                    查看详情
                  </Link>
                </div>
              );
            }}
          </SQueryOne>
        </div>

        <div className="main-title">
          <h3>
            {routesMap[openinfoOptions.channelNo] &&
              routesMap[openinfoOptions.channelNo].channelName}
          </h3>
        </div>
        <div className="openinfo">
          <div className="top">
            <h3 className="tip">{window.openinfo}</h3>
            <ul className="time">
              {time.map(item => (
                <li key={item.id}>
                  <p className="t">{item.time}</p>
                  <p className="title">{item.title}</p>
                </li>
              ))}
            </ul>
          </div>
          <ul className="bottom">
            {aList.map(item => (
              <li key={item.id}>
                <NavLink
                  to={item.linkUrl}
                  onClick={e => this.handleClick(e, item.linkUrl, item.name)}
                >
                  <i className={item.classname}></i>
                  <span className="title">{item.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="main-title">
          <h3>
            {routesMap[snewsOptions.channelNo] &&
              routesMap[snewsOptions.channelNo].channelName}
          </h3>
        </div>
        <div className="snews">
          <Link to={`/survey/snews`} className="viewmore">
            查看更多
          </Link>
          <SQueryList type="QUERY_LIST" options={snewsOptions}>
            {props => (
              <>
                <ul className="list">
                  {props.records.map((item, index) => {
                    const md = getDateofMonthandDay(item.publishDate);
                    return (
                      <li
                        className={active === index ? 'active' : null}
                        key={item.id}
                        onMouseEnter={() => {
                          this.handleMouseEnter(index);
                        }}
                      >
                        <div className="time">
                          <span>{md.m}</span>
                          {`/${md.d}`}
                        </div>
                        <MyLink
                          linkUrl={item.linkUrl}
                          to={`/survey/snews/${item.id}`}
                        >
                          <h3 className="title">{item.title}</h3>
                        </MyLink>
                        <MyLink
                          linkUrl={item.linkUrl}
                          to={`/survey/snews/${item.id}`}
                        >
                          <div className="cont">
                            {HtmltoStr(
                              item.abstract ? item.abstract : item.content,
                              58,
                            )}
                          </div>
                        </MyLink>
                      </li>
                    );
                  })}
                </ul>
                <ul className="img-list">
                  {props.records.map((item, index) => (
                    <li
                      className={active === index ? 'active' : null}
                      key={item.id}
                    >
                      <div className="imgstyle">
                        <div
                          className="img"
                          style={{
                            backgroundImage: item.imgSrc
                              ? `url(${item.imgSrc})`
                              : null,
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </SQueryList>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  routesMap: state.toproutes.routesMap,
});
export default connect(mapStateToProps)(Survey);
