import React from 'react';
import MyLink from '../../../components/MyLink';
import './index.scss';

const UI = props => {
  return (
    <ul className="textlist">
      {props.data.map(item => {
        return (
          <li key={item.id}>
            <MyLink
              linkUrl={item.linkUrl}
              to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
            >
              <h3>{item.title}</h3>
              {/* <p>信息来源：{item.source ? item.source : '天一阁博物馆'}</p> */}
              {item.author ? <p>作者：{item.author}</p> : ''}
              <span>{item.publishDate}</span>
            </MyLink>
          </li>
        );
      })}
    </ul>
  );
};

export default UI;
