import React, { Component } from 'react';
import { SQueryOne } from '@mpfe/cskit';
import ReactWOW from 'react-wow';
import { Link } from 'react-router-dom';
import { HtmltoStr } from '@/utils';
export default class Survey extends Component {
  state = {};
  render() {
    return (
      <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.2s">
        <section className="index-survey">
          <div className="fstitle">
            <h3 className="name">武博概况</h3>
          </div>
          <div className="box">
            <SQueryOne
              type="QUERY_ONE_BY_NO"
              options={{ channelNo: 'introduction' }}
            >
              {props => {
                return (
                  <Link to={`/survey/introduction`}>
                    <div className="imgstyle">
                      <div
                        className="img"
                        style={{
                          backgroundImage: props.data.imgSrc
                            ? `url(${props.data.imgSrc})`
                            : null,
                        }}
                      />
                    </div>
                  </Link>
                );
              }}
            </SQueryOne>
            <SQueryOne
              type="QUERY_ONE_BY_NO"
              options={{ channelNo: 'opengov' }}
            >
              {props => {
                return (
                  Object.keys(props.data).length > 0 && (
                    <Link to={`/survey/opengov/${props.data.id}`}>
                      <div className="imgstyle opengovbox">
                        <p>{HtmltoStr(props.data.title, 20)}</p>
                        <i></i>
                      </div>
                    </Link>
                  )
                );
              }}
            </SQueryOne>
          </div>
        </section>
      </ReactWOW>
    );
  }
}
