import React, { Component } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import cls from 'classnames';
export default class FirstMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 1,
    };
  }
  isActive(match, location, to) {
    if (!match) return false;
    return location.pathname.split('/').indexOf(to) > -1;
  }

  render() {
    const { routes } = this.props;
    const firstmenu =
      (this.props.location && this.props.location.pathname.split('/')[1]) ||
      'home';
    const secmenu =
      this.props.location && this.props.location.pathname.split('/')[2];
    return (
      <ul className={'fsmenu'}>
        {routes &&
          routes.map((item, index) => {
            return (
              <li
                key={item.id}
                className={cls({
                  nochild:
                    !(item.channelNo && item.subChannels.length > 0) ||
                    item.channelNo === 'home',
                  more5: item.subChannels && item.subChannels.length > 5,
                  active: item.channelNo === firstmenu,
                  right: index > 3,
                })}
                onMouseOver={
                  item.subChannels.length > 0 ? this.props.handleMouseOut : null
                }
                onMouseOut={
                  item.subChannels.length > 0 ? this.props.handleMouseOut : null
                }
              >
                <NavLink
                  to={{
                    pathname: item.isHome
                      ? '/'
                      : item.channelNo === 'ccp'
                      ? '/ccp'
                      : `/${item.channelNo}`,
                  }}
                  //    /${item.subChannels[0]&&item.subChannels[0].channelNo}
                  isActive={(match, location) =>
                    this.isActive(match, location, item.channelNo)
                  }
                >
                  {item.channelName}
                </NavLink>
                <ul className="secmenu">
                  {item.subChannels.map(sub => {
                    return (
                      <li
                        key={sub.id}
                        className={cls({
                          active: sub.channelNo === secmenu,
                        })}
                      >
                        {sub.isInnerJump && sub.linkUrl && !sub.isHref ? (
                          <NavLink
                            smooth
                            to={{
                              pathname: `/${item.channelNo}`,
                              hash: sub.linkUrl,
                            }}
                            isActive={() => {
                              return false;
                            }}
                          >
                            {sub.channelName}
                          </NavLink>
                        ) : sub.isHref ? (
                          <a
                            href={sub.linkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {sub.channelName}
                          </a>
                        ) : (
                          <NavLink
                            to={{
                              pathname: `/${item.channelNo}/${sub.channelNo}`,
                            }}
                            isActive={() => {
                              return false;
                            }}
                          >
                            {sub.channelName}
                          </NavLink>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
      </ul>
    );
  }
}
