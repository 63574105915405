import React, { Component } from 'react';
import { connect } from 'react-redux';
import Baidumap from './baidumap';
import { SQueryOne } from '@mpfe/cskit';
import './Openinfo.scss';
import GuideImg from '@/assets/images/desktop/index/guide-img.png';

class Openinfo extends Component {
  render() {
    return (
      <>
        <div className="main-title" id="order" name="order">
          <h3>联系方式与开放时间</h3>
        </div>
        <div className="openhour">
          <ul className="openhourleft">
            <li className="opentele"></li>
            <li>027-85601720</li>
            <li className="openmail"></li>
            <li>{window.email}</li>
          </ul>
          <div className="openhourright">
            <h3>{window.openinfo}</h3>
            <ul>
              <li>
                <h2>{window.opentime}</h2>
                <p>入馆</p>
              </li>
              <li>
                <h2>{window.stoptime}</h2>
                <p>停止领票入场</p>
              </li>
              <li>
                <h2>{window.closetime}</h2>
                <p>闭馆</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="main-title" id="traffic" name="traffic">
          <h3>公共交通</h3>
        </div>
        <div className="publictran">
          <Baidumap></Baidumap>
          <ul className="publicbus">
            <li>
              <div>
                <span className="subway"></span>
                <h3>轨道交通2号线汉口火车站站、轨道交通3号线范湖站</h3>
              </div>
            </li>

            <li>
              <div>
                <span className="bus"></span>
                <h3>青年路市博物馆公交站</h3>
              </div>
              <p>停靠车辆：10、79、342、411、509、584、603、703、809路公交车</p>
            </li>
            <li>
              <div>
                <span className="parking"></span>
                <h3>
                  停车：参观车辆由博物馆工作人员引导到停车场指定车位有序停放
                </h3>
              </div>
            </li>
            <li>
              <div>
                <span className="add"></span>
                <h3>武汉市江汉区青年路373号</h3>
              </div>
            </li>
          </ul>
        </div>
        <div className="main-title">
          <h3>服务设施</h3>
        </div>
        <div className="surbox">
          <SQueryOne
            type="QUERY_ONE_BY_NO"
            options={{ channelNo: 'sfacilities' }}
          >
            {props => {
              return (
                <div
                  className="sur"
                  dangerouslySetInnerHTML={{
                    __html: props.data.content,
                  }}
                />
              );
            }}
          </SQueryOne>
        </div>
        <div className="main-title" id="intention">
          <h3>参观须知</h3>
        </div>
        <div className="visitnotes">
          {/*           <SQueryOne
            type="QUERY_ONE_BY_NO"
            options={{ channelNo: 'visitnotes' }}
          >
            {props => {
              return (
                <div
                  className="cont"
                  dangerouslySetInnerHTML={{
                    __html: props.data.content,
                  }}
                />
              );
            }}
          </SQueryOne> */}
          <div className="cont">
            <div className="left">
              <h3>购票须知</h3>
              <p>
                武汉博物馆依法对公众实行免费开放，个人参观无需预约，持身份证核验信息入馆。
              </p>
              <p>团队参观请提前24小时预约。预约电话 027-85601720。</p>
              <p>
                武汉博物馆日参观最大承载量4000人，瞬时最大承载量1500人，达到最大承载量时会暂停入馆。
              </p>
              <h3>是否可以拍照？</h3>
              <p>如欲拍照留念，请遵守以下规则：</p>
              <p>在参观丝织品、木质品等文物时，请勿拍照；</p>
              <p>请勿使用摄影设备（含自拍竿）；</p>
              <p>请勿使用闪光灯；</p>
              <p>请勿阻碍其他观众；</p>
              <p>未经许可，照片不得作任何商业用途或公开展示。</p>
            </div>
            <div className="right">
              <h3>食物和饮料是否可以进馆？</h3>
              <p>进入博物馆需要进行安检，食物是不允许带进馆内的；</p>
              <p>瓶装水经过安检后，可以带入。</p>
              <h3>哪些物品不能带入场馆？</h3>
              <p>宠物、打火机、易燃易爆物及危险品禁止带入；</p>
              <p>雨伞及手提箱请寄存，请勿带入展厅。</p>
              <h3>其他注意事项</h3>
              <p>请勿吸烟；</p>
              <p>请保持安静；</p>
              <p>请勿触摸展品；</p>
              <p>请勿录音或录影；</p>
              <p>请勿在休息区域躺卧。</p>
            </div>
          </div>
        </div>
        <div className="main-title" id="guide">
          <h3>导览</h3>
        </div>
        <div className="guide-img">
          <img src={GuideImg} alt="导览"></img>
        </div>
      </>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(Openinfo);
