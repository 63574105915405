import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';
export default withRouter(
  class GoBack extends Component {
    // static defaultProps = {
    //   msg: language === "cn" ? '返回' : (language === 'en' ? 'GoBack' : (language === 'hw' ? '되돌아가다' : '戻る')),
    // };
    goBack = () => {
      this.props.history.goBack();
    };
    render() {
      const canBack = this.props.history.length > 1;
      //   const msg = '返回';
      return (
        <li
          className={`goback ${canBack ? '' : 'disable'}`}
          onClick={canBack ? this.goBack : null}
        >
          {/* {msg}
          <i>></i> */}
        </li>
      );
    }
  },
);
