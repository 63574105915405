// import axios from 'axios';
import HttpClient from './HttpClient';
// import { Encrypt } from './aes';
import aes from './aes';

const api = window.API;

const http = HttpClient.create(
  {
    baseURL: api,
  },
  [[aes]],
);
// http.interceptors.request.use(config => {
//     config.headers.appId = '682beecf9ae1499fb88d5b2b781d6dee';
//     config.headers.token = Encrypt('F046C25F5636BC20F7DFDAE56F74F0D4')
//     return config;
// })
export default http;
