import React, { Component } from 'react';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';
import { connect } from 'react-redux';
import { deleteHtmlTag } from '../../../../utils';
import './index.scss';
class LookBigImg extends Component {
  viewer = null;
  componentDidMount() {
    if (true) {
      this.viewer = new Viewer(document.getElementById('photoviewer'), {
        inline: false,
        url: 'data-original',
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.viewer && this.viewer.destroy();
      this.viewer = new Viewer(document.getElementById('photoviewer'), {
        inline: false,
        url: 'data-original',
        title: false,
      });
    }
  }
  componentWillUnmount() {
    this.viewer && this.viewer.destroy();
  }
  render() {
    return (
      <div id="photoviewer">
        <ul className="common-imglist">
          {this.props.data.map(item => {
            return (
              <li key={item.id}>
                <div className="img c imgstyle use-img ">
                  <img
                    style={{ objectFit: 'cover' }}
                    src={
                      item.filePath
                        ? item.filePath
                        : item.imgSrc
                        ? item.imgSrc
                        : null
                    }
                    alt={item.title ? item.title : null}
                    data-original={
                      item.appimgSrc
                        ? item.appimgSrc
                        : item.filePath
                        ? item.filePath
                        : item.imgSrc
                        ? item.imgSrc
                        : null
                    }
                  />
                </div>
                {item.title ? (
                  <div className="cont">
                    <h3>{item.title}</h3>
                    {item.subTitle ? (
                      <p className="size">{deleteHtmlTag(item.subTitle)}</p>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(LookBigImg);
