import React from 'react';
import { HtmltoStr } from '@/utils';
// import { Link } from 'react-router-dom';
import { SQueryList } from '@mpfe/cskit';
import MyLink from '../../../../components/MyLink';
import '../index.scss';
const BaseExhListUI = props => {
  return (
    <ul className="baseexhlist">
      {props.data.map(item => {
        return (
          <li key={item.id}>
            <MyLink
              linkUrl={item.linkUrl}
              to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
              className="lia"
            >
              <div className="imgstyle">
                <div
                  className="img"
                  style={{
                    backgroundImage: item.imgSrc ? `url(${item.imgSrc})` : null,
                  }}
                />
              </div>
              <div className="cont">
                <a href={`/${props.firstmenu}/${props.secmenu}/${item.id}`}>
                  查看详情
                </a>
                <h5>{item.title}</h5>
                <div className="abstract">
                  <p>
                    本展必看：
                    <SQueryList
                      type="QUERY_COLLIST_BYEXHID"
                      options={{
                        groupId: item.groupId,
                        pageSize: 8,
                      }}
                    >
                      {props => {
                        return (
                          <>
                            {props.records.length !== 0 ? (
                              <>
                                {props.records.map(item => {
                                  return item.title + '；';
                                })}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        );
                      }}
                    </SQueryList>
                  </p>
                  <p className="abstractp"> {HtmltoStr(item.content, 115)} </p>
                </div>
              </div>
            </MyLink>
          </li>
        );
      })}
    </ul>
  );
};
export default BaseExhListUI;
