import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import TempExhListUI from './TempExhListUI';
import Pager from '@mpfe/pager';
class VirtualExhibitionHall extends Component {
  render() {
    const firsturl =
      (this.props.location && this.props.location.pathname.split('/')[1]) ||
      'home';
    const secondurl =
      this.props.location && this.props.location.pathname.split('/')[2];
    const { pagerOption, onPageChange } = this.props;
    const options = { ...pagerOption, channelNo: 'virtualexhibitionhall' };
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[secondurl] &&
              this.props.routes.routesMap[secondurl].channelName}
          </h3>
        </div>
        <SQueryList type="QUERY_LIST" options={options}>
          {props => {
            return (
              <>
                <TempExhListUI
                  data={props.records}
                  firstmenu={firsturl}
                  secmenu={secondurl}
                />
                {/* <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page =>
                    props.fetchHandle({
                      currentPage: page,
                      pageSize:
                        secondurl === 'tempexhibition'
                          ? this.options_tempexhibition.pageSize
                          : this.options_specialexhibition.pageSize,
                      exhtype:
                        secondurl === 'tempexhibition'
                          ? this.options_tempexhibition.exhtype
                          : this.options_specialexhibition.exhtype,
                    })
                  }
                ></Pager> */}
                <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  forcePage={pagerOption.currentPage}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page => {
                    onPageChange(page);
                  }}
                ></Pager>
              </>
            );
          }}
        </SQueryList>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(VirtualExhibitionHall);
