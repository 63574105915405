import React, { Component } from 'react';
import ReactWOW from 'react-wow';
import { NavHashLink as NavLink } from 'react-router-hash-link';
export default class Guide extends Component {
  state = {};
  scrollToAnchor = anchorName => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    }
  };
  render() {
    return (
      <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.6s">
        <section className="index-guide">
          <div className="fstitle">
            <h3 className="name">参观指南</h3>
          </div>
          <div className="box">
            <div className="time">
              <p className="time-p">
                {window.opentime} ~ {window.closetime}
              </p>
            </div>
            <p className="cont">{window.openinfo}</p>
            <p className="info">
              <i className="iconfont icondingwei"></i>武汉市江汉区青年路373号
            </p>
            <p className="info">
              <i className="iconfont icondianhua"></i>027-85601720
            </p>
            <p className="info">
              <i className="iconfont iconyouxiang"></i>
              {window.email}
            </p>
            <ul className="list">
              <li className="item">
                <NavLink
                  to={{ pathname: `/survey/openinfo`, hash: `#traffic` }}
                >
                  <i className="iconfont iconjiaotong"></i>
                  <span className="name">交通</span>
                </NavLink>
              </li>
              <li className="item">
                <NavLink to={{ pathname: `/survey/openinfo`, hash: `#guide` }}>
                  <i className="iconfont iconditu"></i>
                  <span className="name">导览</span>
                </NavLink>
              </li>
              <li className="item">
                <NavLink to="/survey/openinfo#intention">
                  <i className="iconfont iconzhuyi"></i>
                  <span className="name">注意</span>
                </NavLink>
              </li>
              {/* <li className="item">
                <NavLink to={{ pathname: `/survey/openinfo`, hash: `#order` }}>
                  <i className="iconfont iconyuyue"></i>
                  <span className="name">预约</span>
                </NavLink>
              </li> */}
            </ul>
          </div>
        </section>
      </ReactWOW>
    );
  }
}
