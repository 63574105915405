import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import JournalListUI from './JournalListUI';
import Pager from '@mpfe/pager';
import qs from 'query-string';
import './ForumList.scss';
import '../index.scss';
class JournalList extends Component {
  constructor(props) {
    super(props);
    const data = qs.parse(this.props.location.search);
    this.state = {
      activeItem: data ? data.tab : 'paperprotect',
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const data = qs.parse(nextProps.location.search);
    const { activeItem } = prevState;
    if (data.tab && data.tab.length > 0 && activeItem !== data.tab) {
      return {
        activeItem: data.tab,
      };
    }
    return null;
  }
  render() {
    const firmenu = this.props.location.pathname.split('/')[1];
    const secmenu = this.props.location.pathname.split('/')[2];
    const { pagerOption, onPageChange } = this.props;
    const options = { ...pagerOption, channelNo: secmenu };
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[secmenu] &&
              this.props.routes.routesMap[secmenu].channelName}
          </h3>
        </div>
        <SQueryList namespace={secmenu} options={options} type="QUERY_LIST">
          {props => {
            return (
              <>
                <div className="jo-list-box">
                  <JournalListUI
                    data={props.records}
                    firstmenu={firmenu}
                    secmenu={secmenu}
                  />
                  <Pager
                    containerClassName="mypager"
                    pagerClassName="sw-pages"
                    pageCount={props.pageCount}
                    forcePage={pagerOption.currentPage}
                    hrefCreator={page => {
                      return `?page=${page}`;
                    }}
                    onPageChange={page => {
                      onPageChange(page);
                    }}
                  ></Pager>
                </div>
              </>
            );
          }}
        </SQueryList>
      </>
    );
  }
  handleFirstClick(channel, no) {
    this.setState({
      [no]: channel,
    });
    const url = `${this.props.location.pathname}?tab=${channel}`;
    // 改变url 便于刷新显示
    this.props.history.push(url);
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(JournalList);
