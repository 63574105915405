import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import BaseExhListUI from './BaseExhListUI';
import Pager from '@mpfe/pager';
class BaseExhList extends Component {
  render() {
    // const language = sessionStorage.getItem('language');
    const firsturl = 'exhibition';
    const secondurl = 'baseexhibition';
    // const channel = language === 'cn' ? 'tempexh' : `tempexhibition-${language}`;
    const { pagerOption, onPageChange } = this.props;
    const options_baseexhibition = {
      ...pagerOption,
      channelNo: 'baseexhibition',
      languageType: sessionStorage.getItem('language'),
    };
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap['baseexhibition'] &&
              this.props.routes.routesMap['baseexhibition'].channelName}
          </h3>
        </div>
        <SQueryList options={options_baseexhibition} type="QUERY_BASE">
          {props => {
            return (
              <>
                <BaseExhListUI
                  data={props.records}
                  firstmenu={firsturl}
                  secmenu={secondurl}
                />

                <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  forcePage={pagerOption.currentPage}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page => {
                    onPageChange(page);
                  }}
                ></Pager>
              </>
            );
          }}
        </SQueryList>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(BaseExhList);
