import '../../polyfills/ie';
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ClsNameFix from '../components/clsNameFix';
import './styles/slick.min.css';
import './styles/all.scss';
import { Provider } from 'react-redux';
import app from '../../mpa';
import { Preset } from '@mpfe/cskit';
import { contextProvider } from '../../mpa/dataProvider';
import { PagerConfig } from '@mpfe/pager';
// app.store.dispatch(topRoutesModel.actions.start);
// app.store.dispatch({ type: 'QUERY_newexhibition_LIST_REQUEST_START' });

let pagerValue;

app.store.dispatch(app.actions('toproutes').start());

// app.store.dispatch({ type: 'toproutes' });

ReactDOM.render(
  <Provider store={app.store}>
    <BrowserRouter>
      {/* <SiteStatistics> */}
      <Preset.Provider
        value={{
          service: contextProvider,
        }}
      >
        <ClsNameFix>
          <PagerConfig.Provider value={pagerValue}>
            <App />
          </PagerConfig.Provider>
        </ClsNameFix>
      </Preset.Provider>
      {/* </SiteStatistics> */}
    </BrowserRouter>
  </Provider>,

  document.getElementById('app'),
);
