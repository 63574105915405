import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageListbBig from '../../../common/ImageListbBig';
import '../index.scss';
import { SQueryList } from '@mpfe/cskit';
import Pager from '@mpfe/pager';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import qs from 'query-string';
class ColList extends Component {
  constructor(props) {
    super(props);
    const name_url = this.props.location.search
      ? qs.parse(this.props.location.search).name
      : '';
    const age_rul = this.props.location.search
      ? qs.parse(this.props.location.search).ageid
      : '';
    const agename_rul = this.props.location.search
      ? qs.parse(this.props.location.search).agename
      : '';
    this.state = {
      name: name_url,
      ageIndex: age_rul ? Number.parseInt(age_rul.substr(5, 2) - 1) : -1,
      ageSate: false,
      ageText: agename_rul ? agename_rul : '年代',
      formAge: age_rul,
      formText: {
        name: name_url,
        age: age_rul,
        type: props.match.params.channelNo,
      },
      options: {
        pageSize: 12,
        exhibitName: '',
        channelNo: props.match.params.channelNo,
      },
    };
  }
  componentDidMount() {
    const that = this;
    $(document).click(function(e) {
      var target = $(e.target);
      if (target.closest($('.selectType.age .border')).length === 0) {
        $(that.age)
          .parent()
          .parent()
          .removeClass('active');
        that.setState({
          ageSate: false,
        });
      }
    });
  }
  AgeClick = () => {
    const ageSate = !this.state.ageSate;
    this.setState({
      ageSate,
    });
  };
  AgeIndex = (index, text, val) => {
    this.setState({
      ageIndex: index,
      formAge: val,
      ageText: text,
    });
  };
  handleChange = e => {
    let name = e.target.value;
    this.setState({
      name,
    });
  };
  submit = () => {
    this.setState({
      formText: {
        name: this.state.name,
        age: this.state.formAge,
        type: this.props.location.pathname.split('/')[2],
      },
    });
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?name=${this.state.name || ''}&ageid=${this.state.formAge ||
        ''}&agename=${this.state.ageText || ''}&page=1`,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    // 从props中获取state
    if (nextProps.match.params.channelNo !== prevState.options.channelNo) {
      return {
        options: {
          ...prevState.options,
          channelNo: nextProps.match.params.channelNo,
        },
        formText: { name: '', age: '', type: '' },
        name: '',
        ageIndex: -1,
        ageSate: false,
        ageText: '年代',
        formAge: '',
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const k = Object.keys(this.state.formText);
    //如果是formText引起的不触发render，page才触发
    return !k.some(
      item => this.state.formText[item] !== nextState.formText[item], //如果有一个满足条件，返回true
    );
  }
  render() {
    const firstmenu = this.props.location.pathname.split('/')[1];
    const secmenu = this.props.location.pathname.split('/')[2];
    const linkUrl = `/${firstmenu}/${secmenu}/detail`;
    const { options } = this.state;
    const { pagerOption, onPageChange } = this.props;
    const copy = {
      ...options,
      ...pagerOption,
    };
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routesMap[secmenu] &&
              this.props.routesMap[secmenu].channelName}
          </h3>
        </div>
        <div className="searchbar colsearch">
          <form>
            <ul>
              <li>
                <div className="inputType border">
                  <input
                    type="text"
                    placeholder="名称"
                    id="title"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
              </li>
              <li>
                <div
                  className={`selectType ${
                    this.state.ageSate ? 'active' : ''
                  } age`}
                >
                  <div className="border">
                    <span
                      className="choseVal"
                      data-value=""
                      id="year"
                      onClick={this.AgeClick}
                    >
                      {this.state.ageText}
                    </span>
                    <i className="toggleIcon" onClick={this.AgeClick}></i>
                  </div>
                  <ul>
                    <li
                      onClick={() => this.AgeIndex(-1, '年代', '')}
                      className={-1 === this.state.ageIndex ? 'active' : ''}
                    >
                      不限年代
                    </li>
                    <SQueryList
                      options={{ namespace: 'CMS_EXHIBIT_AGE_OPTION' }}
                      type="QUERY_Dictionary"
                    >
                      {props => (
                        <>
                          {props.records.map((item, index) => {
                            return (
                              <li
                                onClick={() =>
                                  this.AgeIndex(
                                    index,
                                    item.text,
                                    item.dictionaryValue,
                                  )
                                }
                                key={item.id}
                                className={
                                  index === this.state.ageIndex ? 'active' : ''
                                }
                              >
                                {item.text}
                              </li>
                            );
                          })}
                        </>
                      )}
                    </SQueryList>
                  </ul>
                </div>
              </li>
            </ul>
            <input
              className="searchbtn"
              value="搜　索"
              type="button"
              onClick={this.submit}
            ></input>
          </form>
        </div>
        <div className="collection-list">
          {!(
            this.state.formText.name === '' && this.state.formText.age === ''
          ) ? (
            <SQueryList
              type="search_Collection"
              options={{ ...this.state.formText, ...pagerOption }}
            >
              {props => (
                <>
                  <div className="collection-list">
                    <ul className="common-imglist">
                      {props.records.map((item, index) => {
                        return (
                          <li key={item.id}>
                            <Link to={`/${firstmenu}/${secmenu}/${item.id}`}>
                              <div className="imgstyle imgfull imgscale">
                                <div
                                  className={`img`}
                                  style={{
                                    backgroundImage: item.imgSrc
                                      ? `url(${item.imgSrc})`
                                      : null,
                                  }}
                                ></div>
                              </div>
                              <div className="cont">
                                <h3>{item.title}</h3>
                                <p className="list-year">
                                  &nbsp;
                                  {item.ageDetail ? item.ageDetail : item.age}
                                  &nbsp;
                                </p>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                    <Pager
                      containerClassName="mypager"
                      pagerClassName="sw-pages"
                      pageCount={props.pageCount}
                      forcePage={pagerOption.currentPage}
                      hrefCreator={page => {
                        return `?page=${page}`;
                      }}
                      onPageChange={page => {
                        onPageChange(page);
                      }}
                    ></Pager>
                  </div>
                </>
              )}
            </SQueryList>
          ) : (
            <ImageListbBig
              linkUrl={linkUrl}
              firstmenu={firstmenu}
              secmenu={secmenu}
              pagerOption={pagerOption}
              options={copy}
              onPageChange={onPageChange}
            />
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  routesMap: state.toproutes.routesMap,
});
export default connect(mapStateToProps)(ColList);
