import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.scss';
import Pager from '@mpfe/pager';
import $ from 'jquery';
import qs from 'query-string';
class Colletion extends Component {
  constructor(props) {
    super(props);
    const name_url = this.props.location.search
      ? qs.parse(this.props.location.search).name
      : '';
    const age_rul = this.props.location.search
      ? qs.parse(this.props.location.search).ageid
      : '';
    const agename_rul = this.props.location.search
      ? qs.parse(this.props.location.search).agename
      : '';
    const type_url = this.props.location.search
      ? qs.parse(this.props.location.search).type
      : '';
    this.state = {
      name: name_url,
      ageIndex: age_rul ? Number.parseInt(age_rul.substr(5, 2) - 1) : -1,
      ageSate: false,
      ageText: agename_rul ? agename_rul : '年代',
      formAge: age_rul,
      typeSate: false,
      typeText: type_url ? type_url : '',
      formType: type_url,
      formText: {
        name: name_url,
        age: age_rul,
        type: type_url,
      },
    };
  }
  componentDidMount() {
    const that = this;
    $(document).click(function(e) {
      var target = $(e.target);
      if (target.closest($('.selectType.age .border')).length === 0) {
        $(that.age)
          .parent()
          .parent()
          .removeClass('active');
        that.setState({
          ageSate: false,
        });
      }
      if (target.closest($('.selectType.type .border')).length === 0) {
        $(that.type)
          .parent()
          .parent()
          .removeClass('active');
        that.setState({
          typeSate: false,
        });
      }
    });
  }
  AgeClick = () => {
    const ageSate = !this.state.ageSate;
    this.setState({
      ageSate,
    });
  };
  AgeIndex = (index, text, val) => {
    this.setState({
      ageIndex: index,
      formAge: val,
      ageText: text,
    });
  };

  TypeClick = () => {
    const typeSate = !this.state.typeSate;
    this.setState({
      typeSate,
    });
  };
  TypeIndex = val => {
    this.setState({
      formType: val,
      typeText: val,
    });
  };
  handleChange = e => {
    let name = e.target.value;
    this.setState({
      name,
    });
  };
  submit = () => {
    this.setState({
      formText: {
        name: this.state.name,
        age: this.state.formAge,
        type: this.state.formType,
      },
    });
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?name=${this.state.name || ''}&ageid=${this.state.formAge ||
        ''}&agename=${this.state.ageText || ''}&type=${this.state.formType ||
        ''}&page=1`,
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    const k = Object.keys(this.state.formText);
    //如果是formText引起的不触发render，page才触发
    return !k.some(
      item => this.state.formText[item] !== nextState.formText[item], //如果有一个满足条件，返回true
    );
  }
  render() {
    const firmenu = this.props.location.pathname.split('/')[1];
    const { pagerOption, onPageChange } = this.props;
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[firmenu] &&
              this.props.routes.routesMap[firmenu].channelName}
          </h3>
        </div>
        <div className="searchbar ">
          <form>
            <ul>
              <li>
                <div className="inputType border">
                  <input
                    type="text"
                    placeholder="名称"
                    id="title"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name || ''}
                  />
                </div>
              </li>
              <li>
                <div
                  className={`selectType ${
                    this.state.ageSate ? 'active' : ''
                  } age`}
                >
                  <div className="border">
                    <span
                      className="choseVal"
                      data-value=""
                      id="year"
                      onClick={this.AgeClick}
                    >
                      {this.state.ageText}
                    </span>
                    <i className="toggleIcon" onClick={this.AgeClick}></i>
                  </div>
                  <ul>
                    <li
                      onClick={() => this.AgeIndex(-1, '年代', '')}
                      className={-1 === this.state.ageIndex ? 'active' : ''}
                    >
                      不限年代
                    </li>
                    <SQueryList
                      options={{ namespace: 'CMS_EXHIBIT_AGE_OPTION' }}
                      type="QUERY_Dictionary"
                    >
                      {props => (
                        <>
                          {props.records.map((item, index) => {
                            return (
                              <li
                                onClick={() =>
                                  this.AgeIndex(
                                    index,
                                    item.text,
                                    item.dictionaryValue,
                                  )
                                }
                                key={item.id}
                                className={
                                  index === this.state.ageIndex ? 'active' : ''
                                }
                              >
                                {item.text}
                              </li>
                            );
                          })}
                        </>
                      )}
                    </SQueryList>
                  </ul>
                </div>
              </li>
              <li>
                <div
                  className={`selectType ${
                    this.state.typeSate ? 'active' : ''
                  } type`}
                >
                  <div className="border">
                    <span
                      className="choseVal"
                      data-value=""
                      id="class"
                      onClick={this.TypeClick}
                    >
                      {!this.state.typeText
                        ? '分类'
                        : this.props.routes.routesMap[firmenu] &&
                          this.props.routes.routesMap[firmenu].subChannels.map(
                            item => {
                              if (item.channelNo === this.state.typeText) {
                                return item.channelName;
                              }
                              return null;
                            },
                          )}
                    </span>
                    <i className="toggleIcon" onClick={this.TypeClick}></i>
                  </div>
                  <ul>
                    <li
                      onClick={() => this.TypeIndex('')}
                      className={this.state.typeText ? '' : 'active'}
                    >
                      不限分类
                    </li>
                    {this.props.routes.routesMap[firmenu] &&
                      this.props.routes.routesMap[firmenu].subChannels.map(
                        (item, index) => {
                          return (
                            <li
                              onClick={() => this.TypeIndex(item.channelNo)}
                              key={item.id}
                              className={
                                item.channelNo === this.state.typeText
                                  ? 'active'
                                  : ''
                              }
                            >
                              {item.channelName}
                            </li>
                          );
                        },
                      )}
                  </ul>
                </div>
              </li>
            </ul>
            <input
              className="searchbtn"
              value="搜　索"
              type="button"
              onClick={this.submit}
            ></input>
          </form>
        </div>
        {this.props.routes.routesMap[firmenu] && (
          <>
            <div className="col-recommand-image">
              <div className="imgborder">
                <div>
                  <h3>
                    {
                      this.props.routes.routesMap[firmenu].subChannels[0]
                        .channelName
                    }
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: this.props.routes.routesMap[firmenu]
                        .subChannels[0].description,
                    }}
                  ></p>
                  <Link
                    className="more"
                    to={`/${this.props.routes.routesMap[firmenu].channelNo}/${this.props.routes.routesMap[firmenu].subChannels[0].channelNo}`}
                  >
                    查看更多
                  </Link>
                </div>
              </div>
            </div>
            <div className="articles">
              {!(
                this.state.formText.name === '' &&
                this.state.formText.age === '' &&
                this.state.formText.type === ''
              ) ? (
                <SQueryList
                  type="search_Collection"
                  options={{ ...this.state.formText, ...pagerOption }}
                >
                  {props => (
                    <>
                      <ul id="search_list" className="common-imglist">
                        {props.records.map((item, index) => {
                          return (
                            <li key={item.id}>
                              <Link
                                to={`/${this.props.routes.routesMap[firmenu].channelNo}/${item.type}/${item.id}`}
                              >
                                <div className="imgstyle imgfull imgscale">
                                  <div
                                    className={`img`}
                                    style={{
                                      backgroundImage: item.imgSrc
                                        ? `url(${item.imgSrc})`
                                        : null,
                                    }}
                                  ></div>
                                </div>
                                <div className="cont">
                                  <h3>{item.title}</h3>
                                  <p className="list-year">
                                    &nbsp;
                                    {item.ageDetail ? item.ageDetail : item.age}
                                    &nbsp;
                                  </p>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <Pager
                        containerClassName="mypager"
                        pagerClassName="sw-pages"
                        pageCount={props.pageCount}
                        forcePage={pagerOption.currentPage}
                        hrefCreator={page => {
                          return `?page=${page}`;
                        }}
                        onPageChange={page => {
                          onPageChange(page);
                        }}
                      ></Pager>
                    </>
                  )}
                </SQueryList>
              ) : (
                <div className="col-sort">
                  <ul>
                    {this.props.routes.routesMap[firmenu].subChannels.map(
                      (item, index) => {
                        return index === 0 ? null : (
                          <li key={item.id}>
                            <Link
                              to={`/${this.props.routes.routesMap[firmenu].channelNo}/${item.channelNo}`}
                            >
                              <p>{item.channelName}</p>
                              <i>
                                <img src={item.bannerimgSrc} alt="" />
                              </i>
                            </Link>
                          </li>
                        );
                      },
                    )}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(Colletion);
