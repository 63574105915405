import axios from 'axios';

/**
 * 预处理函数生成
 * @param {Array} preprocessor
 * @param {Object} result
 */
function interceptorsGenerator(preprocessor, result) {
  return preprocessor.reduce((a, b) => {
    if (b[0]) {
      a.config.push(b[0]);
    }
    // if (b[1]) {
    //   a.error.push(b[1]);
    // }
    return a;
  }, result);
}

/**
 * 依次执行预处理函数
 * @param {Array} funArr
 * @param {} args
 */
function compose(funArr) {
  if (funArr.length === 1) {
    return funArr[0];
  } else {
    return funArr.reduce((a, b) => (...args) => b(a(...args)));
  }
}

/**
 * 创建一个HTTP Client
 * @param {Object} axios 配置
 * @param {Array} reqPreprocessor request预处理
 * @param {Array} resPreprocessor response 预处理
 */
function createHttpClient(
  config = {},
  reqPreprocessor = [],
  //   resPreprocessor = [],
) {
  const http = axios.create(config);

  const reqInterceptors = interceptorsGenerator(reqPreprocessor, {
    config: [config => config],
    error: [error => error],
  });
  //   const resInterceptors = interceptorsGenerator(resPreprocessor, {
  //     config: [res => res],
  //     error: [error => error],
  //   });

  http.interceptors.request.use(
    config => compose(reqInterceptors.config)(config),
    error => Promise.reject(compose(reqInterceptors.error)(error)),
  );
  //   http.interceptors.response.use(
  //     res => compose(resInterceptors.config)(res),
  //     error => Promise.reject(compose(reqInterceptors.error)(error)),
  //   );
  return http;
}

const HttpClient = {
  create: createHttpClient,
};

export default HttpClient;
