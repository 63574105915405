import React from 'react';
import FirstMenu from '../FirstMenu';
import Selectbox from '../Selectbox';
import Wechat from '../Wechat';
import wx from '@/assets/images/desktop/common/wx.png';
import wb from '@/assets/images/desktop//common/wb.png';
import './index.scss';
import { connect } from 'react-redux';
import { SQueryList } from '@mpfe/cskit';
import MyLink from '../../../components/MyLink';
class Footer extends React.Component {
  render() {
    const { routes } = this.props;
    const linkUrl =
      'http://bszs.conac.cn/sitename?method=show&id=54A617AF97F10D36E053012819AC92CD';
    //const weiboUrl = 'https://weibo.com/u/3202580334?topnav=1&wvr=6&topsug=1&is_hot=1';
    const isSeachPage = window.location.hash.substr(1) === '/SearchPage';
    const year = new Date().getFullYear();
    return (
      <>
        {!isSeachPage ? (
          <footer>
            <div className="secpart">
              <div className="allcenter">
                <FirstMenu routes={routes} />

                <ul className="ewmbox">
                  <li>
                    <p>地址：武汉市江汉区青年路373号</p>
                    <Wechat wx={wx} wb={wb} />
                  </li>
                  <li>
                    <p>服务热线：027-85601720</p>
                  </li>
                  <li>
                    <SQueryList
                      type="QUERY_LIST"
                      options={{ channelNo: 'friendlink', pageSize: 10 }}
                    >
                      {props => {
                        return (
                          <Selectbox
                            data={props.records.length > 0 && props.records}
                          />
                        );
                      }}
                    </SQueryList>
                  </li>
                </ul>
              </div>
            </div>
            <div className="thirdpart">
              <div className="pbox">
                <p>
                  &copy; {year} 武汉博物馆 All Rights Reserved
                  <a
                    href="https://beian.miit.gov.cn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    鄂ICP备05001164号
                  </a>
                </p>
                <span>
                  <MyLink linkUrl={linkUrl} to={''}></MyLink>
                </span>
              </div>
            </div>
          </footer>
        ) : (
          <div className="search-footer">
            <p>&copy; {year} 武汉博物馆</p>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  routes: state.toproutes.records,
});

export default connect(mapStateToProps)(Footer);
