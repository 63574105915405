import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { Link } from 'react-router-dom';
import wx from '@/assets/images/desktop/index/icon-weixin.png';
import wb from '@/assets/images/desktop/index/icon-weibo.png';
import gongan from '@/assets/images/desktop/index/gongan.png';
import Wechat from '../../common/Wechat';
import ReactWOW from 'react-wow';
export default class Footer extends Component {
  render() {
    return (
      <ReactWOW animation="fadeIn-little" duration="1.5s" delay="0.5s">
        <div className="index-footer">
          <div className="index-footer-toppart">
            <div className="index-friendlink">
              <span>友情链接：</span>
              <SQueryList
                options={{ channelNo: 'friendlink', pageSize: '10' }}
                type="QUERY_LIST"
              >
                {props => {
                  return (
                    <ul>
                      {props.records.map(item => {
                        return (
                          <li key={item.title}>
                            <a
                              href={item.linkUrl}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {item.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  );
                }}
              </SQueryList>
              <Link to={{ pathname: '/flink' }} target="_blank">
                查看全部 &gt;
              </Link>
            </div>
            <div className="index-contact">
              <div className="box1">
                <p className="copy">
                  版权声明>
                  <div className="cont">
                    未经允许不得擅自盗用本网站照片或文字，否则追究法律责任
                  </div>
                </p>
                <p className="cont">
                  浏览建议>
                  <div className="cont">
                    谷歌 360极速浏览器 火狐 最佳分辨率(1920*1080)
                  </div>
                </p>
              </div>
              <div className="box2">
                <Wechat wx={wx} wb={wb} />
              </div>
              {/* <div className="wechat">
                <i className="icon"></i>
                <div className="imgbox">
                  <img src={wx} alt="官方微信" />
                </div>
              </div>
              <div className="weibo">
                <i className="icon"></i>
                <div className="imgbox">
                  <img src={wb} alt="官方微博" />
                </div>
              </div> */}
            </div>
          </div>
          <div className="index-footer-bottompart">
            {/* <div className="index-friendlink"></div> */}
            <div className="index-copyright">
              <p className="beian">
                <img src={gongan} alt=""></img>
                公安部联网备案号-63012402000688
              </p>
              <p>
                <a
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  鄂ICP备05001164号
                </a>
              </p>
              <p>版权所有：武汉博物馆</p>
              <span id="_ideConac" className="sydw">
                <a
                  href="//bszs.conac.cn/sitename?method=show&amp;id=54A617AF97F10D36E053012819AC92CD"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    id="imgConac"
                    alt=""
                    src="//dcs.conac.cn/image/blue.png"
                    data-bd-imgshare-binded="1"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </ReactWOW>
    );
  }
}
