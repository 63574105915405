import IndexPage from '../pages/index';

import MainDetail from '../common/MainDetail';
import MainDetailByNo from '../common/MainDetailByNo';

import BaseExhList from '../pages/Exhibition/components/BaseExhList';
import BaseExhDetail from '../pages/Exhibition/components/BaseExhDetail';
import TempExhList from '../pages/Exhibition/components/TempExhList';
import TempExhDetail from '../pages/Exhibition/components/TempExhDetail';
import VirtualExhibitionHall from '../pages/Exhibition/components/VirtualExhibitionHall';

import ColList from '../pages/Collection/components/List';
import ColDetail from '../pages/Collection/components/Detail';
import JournalList from '../pages/Study/components/JournalList';
import FLinks from '../pages/FLinks';

// import Ccp from '../pages/Ccp';
import CcpItemList from '../pages/Ccp/components/List';
import CcpDetail from '../pages/Ccp/components/CcpDetail';

import ImgContList from '../common/ImgContList';
import TextList from '../common/TextList';

import withScrollTo from '../../components/ScrollTo';
// import SearchPage from '../common/SearchPage';

import VideoList from '../pages/Education/components/video';
import StudyDetail from '../pages/Study/components/Detail';

import Openinfo from '../pages/Survey/components/Openinfo';
import pageHOC from '../../components/PageHOC';
import Stydy from '../pages/Study';
import Survey from '../pages/Survey';
import Education from '../pages/Education';
import Exhibition from '../pages/Exhibition';
import Collection from '../pages/Collection';
const mainRoutes = [
  {
    path: '/',
    component: withScrollTo(IndexPage),
    exact: true,
  },
  {
    path: '/survey',
    component: withScrollTo(Survey),
    exact: true,
  },
  {
    path: '/flink',
    component: withScrollTo(FLinks),
    exact: true,
  },
  //   {
  //     path: '/service',
  //     component: withScrollTo(Service),
  //     exact: true,
  //   },
  {
    path: '/ccp',
    component: withScrollTo(pageHOC(CcpItemList, 8)),
    exact: true,
  },
  //   {
  //     path: '/searchpage',

  //     component: withScrollTo(SearchPage),
  //     exact: true,
  //   },
  {
    path: '/education',
    component: withScrollTo(Education),
    exact: true,
  },
];

// 概况
const surveyRoutes = [
  {
    path: '/survey/introduction',
    component: withScrollTo(MainDetailByNo),
    exact: true,
  },
  {
    path: '/survey/openinfo',
    component: withScrollTo(Openinfo),
    exact: true,
  },
  {
    path: '/survey/snews',
    component: withScrollTo(pageHOC(ImgContList, 10)),
    exact: true,
  },
  {
    path: '/survey/opengov',
    component: withScrollTo(pageHOC(TextList, 10)),
    exact: true,
  },

  {
    path: '/survey/:channelNo/:resource',
    component: withScrollTo(MainDetail),
    exact: true,
  },
];

// 展览
const exhRoutes = [
  {
    path: '/exhibition',
    component: withScrollTo(Exhibition),
    exact: true,
  },
  {
    path: '/exhibition/baseexhibition',
    component: withScrollTo(pageHOC(BaseExhList, 5)),
    exact: true,
  },
  {
    path: '/exhibition/baseexhibition/:resource',
    component: withScrollTo(BaseExhDetail),
    exact: true,
  },
  {
    path: '/exhibition/tempexhibition',
    component: withScrollTo(pageHOC(TempExhList, 4)),
    exact: true,
  },
  {
    path: '/exhibition/tempexhibition/:resource',
    component: withScrollTo(TempExhDetail),
    exact: true,
  },
  {
    path: '/exhibition/collect/:id',
    component: withScrollTo(ColDetail),
    exact: true,
  },
  {
    path: '/exhibition/specialexhibition',
    component: withScrollTo(pageHOC(TempExhList, 4)),
    exact: true,
  },
  {
    path: '/exhibition/specialexhibition/:resource',
    component: withScrollTo(TempExhDetail),
    exact: true,
  },
  {
    path: '/exhibition/virtualexhibitionhall',
    component: withScrollTo(pageHOC(VirtualExhibitionHall, 4)),
    exact: true,
  },
];

// 藏品
const colRoutes = [
  {
    path: '/collection',
    component: withScrollTo(pageHOC(Collection, 8)),
    exact: true,
  },
  {
    path: '/collection/:channelNo',
    component: withScrollTo(pageHOC(ColList, 8)),
    exact: true,
  },
  {
    path: '/collection/:channelNo/:id',
    component: withScrollTo(ColDetail),
    exact: true,
  },
];

// 学术
const studyRoutes = [
  {
    path: '/research',
    component: withScrollTo(Stydy),
    exact: true,
  },
  {
    path: '/research/scipublish',
    component: withScrollTo(pageHOC(JournalList, 8)),
    exact: true,
  },
  {
    path: '/research/colresearch',
    component: withScrollTo(pageHOC(TextList, 10)),
    exact: true,
  },
  {
    path: '/research/whwenbo',
    component: withScrollTo(pageHOC(JournalList, 10)),
    exact: true,
  },
  {
    path: '/research/colresearch/:resource',
    component: withScrollTo(MainDetail),
    exact: true,
  },
  {
    path: '/research/scipublish/:resource',
    component: withScrollTo(StudyDetail),
    exact: true,
  },
  {
    path: '/research/whwenbo/:resource',
    component: withScrollTo(StudyDetail),
    exact: true,
  },
  {
    path: '/research/:resource',
    component: withScrollTo(MainDetail),
    exact: true,
  },
];

// 学院
const eduRoutes = [
  {
    path: '/education/publicedu',
    component: withScrollTo(pageHOC(ImgContList, 10)),
    exact: true,
  },
  {
    path: '/education/volunteer',
    component: withScrollTo(pageHOC(ImgContList, 10)),
    exact: true,
  },
  {
    path: '/education/volunteer/:id',
    component: withScrollTo(MainDetail),
    exact: true,
  },
  {
    path: '/education/:channelNo/:resource',
    component: withScrollTo(MainDetail),
    exact: true,
  },
  {
    path: '/education/video',
    component: withScrollTo(pageHOC(VideoList, 4)),
    exact: true,
  },
];

// 文创;
const ccpRoutes = [
  {
    path: '/ccp/:resource',
    component: withScrollTo(CcpDetail),
    exact: true,
  },
];

export const pcRoutes = mainRoutes.concat(
  surveyRoutes,
  exhRoutes,
  colRoutes,
  studyRoutes,
  eduRoutes,
  ccpRoutes,
);
