import React from 'react';
import './index.scss';
import { HtmltoStr } from '@/utils';
import MyLink from '../../../components/MyLink';

const UI = props => {
  return (
    <>
      <ul className="img-cont-list">
        {props.data.map(item => {
          return (
            <li key={item.id}>
              <MyLink
                linkUrl={item.linkUrl}
                to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
              >
                <div className="date">
                  <p>
                    {item.publishDate.split('-')[1]}/
                    {item.publishDate.split('-')[2]}
                  </p>
                  <p>{item.publishDate.split('-')[0]}</p>
                </div>
                <div className="cont">
                  <h3>
                    {item.title}
                    <span>{item.subTitle}</span>
                  </h3>
                  <p>
                    {HtmltoStr(
                      item.abstract ? item.abstract : item.content,
                      70,
                    )}
                  </p>
                </div>
                <div className="imgstyle use-img">
                  {item.imgSrc ? (
                    <img src={item.imgSrc} alt="" />
                  ) : (
                    <div className="img" />
                  )}
                </div>
              </MyLink>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default UI;
