/**
 * 获取url参数
 *
 * @export
 * @param {*} search
 * @returns
 */
export function parseSearch(search) {
  return new URLSearchParams(search);
}

/**
 * 初始化页码
 *
 * @export
 * @param {*} params
 * @returns
 */
export function getInitPage(params) {
  let initPage = 1;
  const page = Number(params.get('page'));
  !page ? (initPage = 1) : (initPage = page);
  return initPage;
}

/**
 * 返回栏目列表
 *
 * @export
 * @param {*} pathname
 * @returns
 */
export function getChannelNo(pathname) {
  //   /news/snews/ ["","news","snews",""]
  const lang = getLanguage();
  const pathnameArray = pathname.split('/').filter(item => item !== '');
  //   if (lang)
  //     pathnameArray.forEach((item, index) => {
  //       pathnameArray[index] = `${item}-${lang}`;
  //     });

  pathnameArray.unshift('');
  const object = pathnameArray.reduce((acc, cur, index) => {
    acc[index] = lang && cur ? `${cur}-${lang}` : cur;
    return acc;
  }, {});
  object.array = pathnameArray;
  return object;
}

export function getLanguage() {
  const lang = new URLSearchParams(window.location.search).get('lang');
  const language = ['en'].includes(lang) ? lang : '';
  return language;
}

export function dealChannelNo(lang) {
  return channelNo => {
    if (lang) {
      return channelNo.split('-')[0];
    }
    return channelNo;
  };
}

export const searchLang = getLanguage() ? `?lang=${getLanguage()}` : '';
export const HyphenLang = getLanguage() ? `-${getLanguage()}` : '';
