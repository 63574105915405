import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
// import { Link } from 'react-router-dom';
import MyLink from '../../../components/MyLink';
import ReactWOW from 'react-wow';
export default class Exhibition extends Component {
  render() {
    return (
      <SQueryList
        type="QUERY_TEMP"
        options={{
          channelNo: 'tempexhibition',
          isRecommend: true,
          pageSize: 2,
        }}
      >
        {prop => {
          return (
            <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.6s">
              <section className="index-exhibition">
                <div className="fstitle">
                  <h3 className="name">展览推荐</h3>
                </div>
                <div className="box">
                  {prop.records.map(item => {
                    return (
                      <MyLink
                        linkUrl={item.linkUrl}
                        to={`/exhibition/${
                          item.type === 'CMS0702'
                            ? `specialexhibition`
                            : `tempexhibition`
                        }/${item.id}`}
                        key={item.id}
                      >
                        <div className="imgstyle">
                          <div
                            className="img"
                            style={{
                              backgroundImage: item.imgSrc
                                ? `url(${item.imgSrc})`
                                : null,
                            }}
                          />
                        </div>
                      </MyLink>
                    );
                  })}
                </div>
              </section>
            </ReactWOW>
          );
        }}
      </SQueryList>
    );
  }
}
