import React, { Component } from 'react';
import FirstMenu from '../FirstMenu';
import './index.scss';
import { connect } from 'react-redux';
import cls from 'classnames';
import InnerBanner from './InnerBanner';
class Header extends Component {
  static defaultProps = {
    index: false,
  };
  handleMouseOver = () => {
    this.setState({ isHover: true });
  };

  handleMouseOut = () => {
    this.setState({ isHover: false });
  };

  render() {
    const { routes, location } = this.props;
    const isSeachPage = window.location.hash.substr(1) === '/SearchPage';
    return (
      <>
        {!isSeachPage ? (
          <>
            <header
              className={cls('', {
                index: this.props.index,
                inner: !this.props.index,
              })}
            >
              <div className="allcenter">
                <a href={'/'}>
                  <div className="logo" />
                </a>
                <div className="rightbox">
                  <FirstMenu
                    routes={routes}
                    location={location}
                    handleMouseOver={this.handleMouseOver.bind(this)}
                    handleMouseOut={this.handleMouseOut.bind(this)}
                    getIndex={this.getIndex}
                  />
                  {/* <ul className="quickmenu">
                    <li className="search">
                      <Link to={'/SearchPage'} target="_blank">
                        <i className="iconfont iconsousuo" />
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
              {/* <div className={cls('secmenu-bg active',
            {
              active: this.state.isHover,
            }
          )}
          /> */}
            </header>
            {this.props.index ? null : <InnerBanner />}
          </>
        ) : (
          <div className="search-header">
            <a href="./">
              <div className="search-logo"></div>
            </a>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  routes: state.toproutes.records,
});

export default connect(mapStateToProps)(Header);
