import React from 'react';
// import { Link } from 'react-router-dom';
import MyLink from '../../../../components/MyLink';
const TempExhListUI = props => {
  return (
    <ul className="tempexhlist">
      {props.data.map(item => {
        return (
          <li key={item.id} className="lisp">
            <MyLink
              linkUrl={item.linkUrl}
              to={`/${props.firstmenu}/${props.secmenu}/${item.id}`}
              className="lia"
            >
              <div className="imgstyle">
                <div
                  className="img"
                  style={{
                    backgroundImage: item.imgSrc ? `url(${item.imgSrc})` : null,
                  }}
                />
              </div>
              <div className="cont">
                <h3>{item.title}</h3>
              </div>
            </MyLink>
          </li>
        );

        // );
      })}
    </ul>
  );
};
export default TempExhListUI;
