import React, { Component } from 'react';
import Survey from './Survey';
import Guide from './Guide';
import News from './News';
import Exhibition from './Exhibition';
import Conbination from './Combination';
import Footer from './Footer';
import './index.scss';
import 'animate.css';
export default class IndexPage extends Component {
  render() {
    return (
      <>
        <div className="index-box">
          <div className="index-leftpart">
            <div className="index-toppart">
              <Survey />
              <News />
              <Exhibition />
            </div>
            <Conbination />
          </div>
          <Guide />
        </div>
        <Footer />
      </>
    );
  }
}
