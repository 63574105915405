import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import Banner from './pages/index/Banner';
import Footer from './common/Footer';
import Header from './common/Header';
import { pcRoutes } from './routes';
import { connect } from 'react-redux';
import Breadcrumb from '../components/Breadcrumb';

class App extends Component {
  componentDidMount() {
    //屏蔽浏览器自带右键事件
    window.oncontextmenu = function() {
      return false;
    };
  }
  render() {
    const { location } = this.props;
    const isIndex = this.props.location.pathname === '/';
    const isSeachPage = this.props.location.pathname === '/SearchPage';
    return (
      <>
        {!isSeachPage ? (
          <>
            <Header index={isIndex ? true : false} location={location} />
            <div className={isIndex ? 'index-cont' : 'inner-cont'}>
              <div className="allcenter">
                {isIndex ? (
                  <Banner />
                ) : (
                  <Breadcrumb locationPath={location.pathname} />
                )}
                <Switch>
                  {pcRoutes.map(route => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  })}
                </Switch>
              </div>
            </div>
            {/* <Footer/> */}
            {isIndex ? '' : <Footer />}
          </>
        ) : (
          <Switch>
            {pcRoutes.map(route => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              );
            })}
          </Switch>
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  routes: state.toproutes.records,
});
export default withRouter(connect(mapStateToProps)(App));
