import React, { Component } from 'react';
import { deleteHtmlTag } from '../../../../../utils';
import { SQueryOne } from '@mpfe/cskit';
import { withRouter } from 'react-router-dom';
// import './index.scss';

//详情

const CcpDetail = function(props) {
  const { params } = props.match;
  const artid = params.id || params.resource;
  return (
    <SQueryOne type="QUERY_ONE_BY_ID" options={{ id: artid }}>
      {props => {
        return <Detail data={props.data} {...props} />;
      }}
    </SQueryOne>
  );
};
export default CcpDetail;
@withRouter
class Detail extends Component {
  render() {
    if (Object.keys(this.props.data).length === 0) return <></>;
    const { imgSrc, title, subTitle, content } = this.props.data;
    return (
      <div className="collectiondetail">
        <div className="topbox">
          <div className="imgstyle">
            <div
              className="img"
              style={{
                backgroundImage: imgSrc ? `url(${imgSrc})` : null,
              }}
            />
          </div>
          <div className="cont">
            <h3>{title}</h3>
            <div className="intro">
              <p className="size">{deleteHtmlTag(subTitle)}</p>
            </div>
            <div
              className="abstract"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
