import React, { Component } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { SQueryList } from '@mpfe/cskit';

class FLinks extends Component {
  render() {
    return (
      <>
        <div className="main-title">
          <h3>友情链接</h3>
        </div>
        <SQueryList
          options={{ channelNo: 'friendlink', pageSize: 48 }}
          type="QUERY_LIST"
        >
          {props => {
            return (
              <ul className="friendly-link clearfix">
                {props.records.map(item => {
                  return (
                    <li key={item.id}>
                      <a
                        href={`${item.linkUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            );
          }}
        </SQueryList>
      </>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(FLinks);
