import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import cls from 'classnames';
import { SQueryList } from '@mpfe/cskit';
import Slider from 'react-slick';
class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      fadeIndex: -1,
      itemCount: 0,
      isFirstDid: true,
    };
    this.timer = null;
    this.animate = this.animate.bind(this);
  }

  componentDidUpdate() {
    if (this.props.records.length > 2 && this.timer === null) {
      this.animate();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onHandleClick = index => {
    clearInterval(this.timer);
    this.setState(
      prevState => ({
        activeIndex: index,
        fadeIndex: prevState.activeIndex,
      }),
      () => this.animate(),
    );
  };

  animate() {
    const length = this.props.records.length;
    this.timer = setInterval(() => {
      this.setState(prevState => {
        const nextActive =
          prevState.activeIndex === length - 1 ? 0 : prevState.activeIndex + 1;
        return {
          activeIndex: nextActive,
          isFirstDid: false,
          fadeIndex: nextActive === 0 ? length - 1 : nextActive - 1,
        };
      });
    }, 6000);
  }

  render() {
    const { activeIndex, fadeIndex } = this.state;
    const { records } = this.props;
    const settings = {
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      pauseOnDotsHover: false,
      autoplaySpeed: 6000,
      fade: true,
    };
    return (
      <div className="cb-slideshowbox">
        {records.length > 2 ? (
          <>
            <ul className="cb-slideshow">
              {records.map((i, index) => (
                <li
                  key={i.id}
                  className={cls({
                    active: activeIndex === index,
                    fade: fadeIndex === index,
                  })}
                >
                  {i.linkUrl ? (
                    i.linkUrl.indexOf('http') !== -1 ? (
                      <a
                        href={i.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          style={{
                            backgroundImage: `url(${i.imgSrc})`,
                          }}
                        />
                      </a>
                    ) : (
                      <Link
                        to={{ pathname: i.linkUrl }}
                        target="_blank"
                        alt={i.title}
                      >
                        <span
                          style={{
                            backgroundImage: `url(${i.imgSrc})`,
                          }}
                        />
                      </Link>
                    )
                  ) : (
                    <span
                      style={{
                        backgroundImage: `url(${i.imgSrc})`,
                      }}
                    />
                  )}
                </li>
              ))}
            </ul>
            <ul className="slide-icon">
              {records.map((i, index) => (
                <li
                  onClick={() => this.onHandleClick(index)}
                  key={index}
                  className={cls({ active: activeIndex === index })}
                />
              ))}
            </ul>
          </>
        ) : (
          <Slider {...settings} key={Math.random()}>
            {records.map(item => {
              return (
                <div className="item" key={item.id}>
                  {item.linkUrl ? (
                    item.linkUrl.indexOf('http') !== -1 ? (
                      <a
                        href={item.linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span
                          style={{
                            backgroundImage: `url(${item.imgSrc})`,
                          }}
                        />
                      </a>
                    ) : (
                      <Link
                        to={{ pathname: item.linkUrl }}
                        target="_blank"
                        alt={item.title}
                      >
                        <span
                          style={{
                            backgroundImage: `url(${item.imgSrc})`,
                          }}
                        />
                      </Link>
                    )
                  ) : (
                    <span
                      style={{
                        backgroundImage: `url(${item.imgSrc})`,
                      }}
                    />
                  )}
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    );
  }
}

export default function() {
  const options_snews = { channelNo: 'slide', pageSize: 10 };
  return (
    <SQueryList type="QUERY_LIST" options={options_snews}>
      {props => <Banner {...props} />}
    </SQueryList>
  );
}
