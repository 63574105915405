import React, { Component } from 'react';
import wx from '@/assets/images/desktop/common/ewm-weixin.png';
import wb from '@/assets/images/desktop/common/ewm-weibo.jpg';
import './index.scss';
export default class Wechat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //flag: false,
    };
    //this.handelClick = this.handelClick.bind(this);
  }

  render() {
    return (
      <>
        <div className="wechat">
          <i
            className="icon"
            style={{
              backgroundImage: this.props.wx ? `url(${this.props.wx})` : null,
            }}
          ></i>
          <div className="imgbox">
            <img src={wx} alt="官方微信" />
          </div>
        </div>
        <div className="weibo">
          <i
            className="icon"
            style={{
              backgroundImage: this.props.wx ? `url(${this.props.wb})` : null,
            }}
          ></i>
          <div className="imgbox">
            <img src={wb} alt="官方微博" />
          </div>
        </div>
        {/* <ul className='webox'>
          <li className='wx'>
            <div className="wxcode">
              <img
                src={WX}
                alt='微信公众号'
              />
            </div>
          </li>
          <li className='wb'>
            <MyLink
              linkUrl={url}
              to={''}
            >
            </MyLink>
          </li>
        </ul> */}
      </>
    );
  }
}
