import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import './index.scss';
import LookBigImg from '../LookBigImg/index';
class MainDetailByNo extends Component {
  render() {
    const channelNo = this.props.location.pathname.split('/')[2];
    const CN = channelNo === 'introduction' ? 'introduction' : '';
    return (
      <div id="photoviewer">
        <SQueryList
          options={{ channelNo: channelNo, pageSize: 1 }}
          type="QUERY_LIST"
        >
          {props => {
            const item = props.records[0] || null;
            return (
              <>
                {item ? (
                  <>
                    <div className={`maindetail`}>
                      <>
                        <div className="main-title">
                          <h3>{item.channelName}</h3>
                        </div>
                        <div
                          className="cont"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </>
                    </div>
                    <div className={`img-detail ${CN}`}>
                      {item.groupId ? (
                        <SQueryList
                          options={{
                            id: item.groupId,
                            code: 'CMS_ARCHIVES_FILE',
                            pageSize: '0',
                          }}
                          type="QUERY_FIELDLIST"
                        >
                          {props => {
                            return (
                              <>
                                {props.records.length !== 0 ? (
                                  <>
                                    <div className="title-img">相关图片</div>
                                    <LookBigImg
                                      data={props.records}
                                    ></LookBigImg>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          }}
                        </SQueryList>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                ) : null}
              </>
            );
          }}
        </SQueryList>
      </div>
    );
  }
}

export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(MainDetailByNo);
