import React, { Component } from 'react';

import { SQueryList } from '@mpfe/cskit';
import { Link } from 'react-router-dom';
import MyLink from '../../../components/MyLink';
import ReactWOW from 'react-wow';
export default class Newsbox extends Component {
  nolist = ['snews', 'publicedu'];
  constructor(props) {
    super(props);
    this.state = {
      noactive: this.nolist[0],
      options: {
        channelNo: this.nolist[0],
        pageSize: 5,
      },
    };
  }
  changeChannel(no) {
    this.setState({
      noactive: no,
      options: {
        channelNo: no,
      },
    });
  }
  render() {
    return (
      <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.4s">
        <section className="index-news">
          <div className="fstitle">
            <h3 className="name">
              <span
                className={
                  this.state.noactive === this.nolist[0] ? 'active' : ''
                }
                onClick={this.changeChannel.bind(this, this.nolist[0])}
              >
                信息资讯
              </span>
              <span
                className={
                  this.state.noactive === this.nolist[1] ? 'active' : ''
                }
                onClick={this.changeChannel.bind(this, this.nolist[1])}
              >
                公共教育
              </span>
            </h3>
            <Link
              to={
                this.state.noactive === this.nolist[0]
                  ? '/survey/snews'
                  : '/education/publicedu'
              }
              className="more"
            >
              更多 +
            </Link>
          </div>
          <div className="box">
            <SQueryList options={this.state.options} type="QUERY_LIST">
              {props => {
                return (
                  <ul className="list">
                    {props.records.map(item => {
                      return (
                        <li key={item.id} className="item">
                          <MyLink
                            linkUrl={item.linkUrl}
                            to={{
                              pathname:
                                this.state.noactive === this.nolist[0]
                                  ? `/survey/snews/${item.id}`
                                  : `/education/publicedu/${item.id}`,
                            }}
                            className="link"
                          >
                            <h3 className="name">
                              {item.title} {item.subTitle}
                            </h3>
                            <p className="date">{item.publishDate}</p>
                          </MyLink>
                        </li>
                      );
                    })}
                  </ul>
                );
              }}
            </SQueryList>
          </div>
        </section>
      </ReactWOW>
    );
  }
}
