// import { objectExpression } from '@babel/types';

// const axios = require('axios');
// import axios from 'axios';
/**
 * author yangming
 * @param 将带标签的html内容转换成string字符串
 * @param {string} content : 需要处理的带<>的字符串
 * @param {number} num : 需要截取的字符串长度（默认为空）
 * @param return 截取之后的字符串
 * @param createTime:2019/3/4
 *
 * @param isEn : 判断是否为英文段落截取
 */
export function HtmltoStr(content, num, isEn) {
  if (!content) {
    return;
  }
  // 处理文本中的HTML标签
  const s = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  // s为空字符串
  if (s === '') return '';
  // 判断是否为英文段落截取
  if (isEn) {
    const str1 = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
    if (str1.length < num) return str1;
    // 截取最后一个空格前的段落（段尾保留完整的英文单词）
    const text = str1.substr(0, Number(num));
    const n = text.lastIndexOf(' ');
    return `${text.substr(0, n)}...`;
  }
  const t = s.replace(/ /g, '');

  if (t.length < num) {
    //如果文字数量少于num，则直接返回
    return `${t}`;
  } else {
    // 需要截断文本长度
    if (num > 0) return `${t.substr(0, Number(num))}...`;
    return `${t}...`;
  }
}

/**
 * @param 处理文本中的HTML标签
 * @param {string} str
 */
export function deleteHtmlTag(str) {
  const str1 = str && str.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  return str1;
}

/**
 * 获取html字符串中第一张图片的地址
 * @param {String} 需要处理的文本
 * @return {String} 处理的结果
 */
export function getFirstImg(content) {
  if (!content) return '';
  //   匹配图片
  const imgReg = /<img.*?(?:>|\/>)/gi;
  //   匹配src
  const srcReg = /src=['"]?([^'"]*)['"]?/i;
  const arr = content.match(imgReg);
  if (arr === null || arr.length < 0) return '';
  //   获取第一张图片的src
  const src = arr[0].match(srcReg);
  return src[1];
}

/**
 *
 * @param {String} 需要验证的文本
 * @return {Object}
 */
export function validator(input) {
  const mobileReg = /^((\+|00)86)?1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
  const usernameReg = /^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/;
  const passworldReg = /^[a-zA-Z0-9_]{6,16}$/;
  // eslint-disable-next-line no-useless-escape
  const emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  const realNameReg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
  const cardNumReg = /^[1-9]\d{5}[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/;
  return {
    isMobilePhobe() {
      return mobileReg.test(input);
    },
    isUserName() {
      return usernameReg.test(input);
    },
    isPassWorld() {
      return passworldReg.test(input);
    },
    isLength(length = 3) {
      return String(input).length >= length;
    },
    isEmpty() {
      return String(input).length === 0;
    },
    isEmail() {
      return emailReg.test(input);
    },
    isRealName() {
      return realNameReg.test(input);
    },
    isCardNum() {
      return cardNumReg.test(input);
    },
    hasText() {
      return !(String(input).length === 0);
    },
    isContent() {
      if (String(input).length >= 10 && String(input).length <= 500) {
        return true;
      }
      return false;
    },
    notUndefined() {
      return typeof input !== 'undefined';
    },
  };
}

//     /.*?[<]\s+script\s+[>].*?(<\/\s+script\s+>)*/
export function validator2() {
  return {
    mobileReg: /^((\+|00)86)?1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/,
    usernameReg: /^[a-zA-Z_][a-zA-Z0-9_]{1,17}$/,
    passworldReg: /^[a-zA-Z0-9_]{6,16}$/,
    // eslint-disable-next-line no-useless-escape
    cemailReg: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    realNameReg: /^([\u4e00-\u9fa5])*$/,
    cardNumReg: /^[1-9]\d{5}[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/,
    contentReg: /.*? [<]\/*.*[>].*/,
  };
}

export function allcenterMinHeight() {
  const w = window.innerWidth;
  const h = window.innerHeight;
  let minHeigt = 0;
  if (w < 1280) {
    minHeigt = h - 100;
  } else if (w < 1366) {
    minHeigt = h - 200;
  } else if (w < 1440) {
    minHeigt = h - 300;
  } else if (w < 1600) {
    minHeigt = h - 400;
  } else {
    minHeigt = h - 420;
  }
  return minHeigt;
}

/**
 * 节流
 *
 * @export
 * @param {*} func
 * @param {number} [time=17]
 * @param {boolean} [options={ leading: true, trailing: false, context: null }]
 * @returns
 */
export function throttle(
  func,
  time = 17,
  options = { leading: true, trailing: false, context: null },
) {
  let previous = new Date(0).getTime();
  let timer;
  const _throttle = function(...args) {
    let now = new Date().getTime();
    if (!options.leading) {
      if (timer) return;
      timer = setTimeout(() => {
        timer = null;
        func.apply(options.context, args);
      }, time);
    } else if (now - previous > time) {
      func.apply(options.context, args);
      previous = now;
    } else if (options.trailing) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(options.context, args);
      }, time);
    }
  };
  _throttle.cancel = () => {
    previous = 0;
    clearTimeout(timer);
    timer = null;
  };
  return _throttle;
}

export function debounce(
  func,
  time = 17,
  options = { leading: true, trailing: true, context: null },
) {
  let timer;
  const _debounce = function(...args) {
    if (timer) clearTimeout(timer);
    if (options.leading && !timer) {
      timer = setTimeout(null, time);
      func.apply(options.content, args);
    } else if (options.trailing) {
      timer = setTimeout(() => {
        func.apply(options.context, args);
        timer = null;
      }, time);
    }
  };
  _debounce.cancel = function() {
    clearTimeout(timer);
    timer = null;
  };
  return _debounce;
}

/**
 * 返回传入值的类型
 *
 * @export
 * @param {*} obj
 * @returns
 */
export function type(obj) {
  return Object.prototype.toString
    .call(obj)
    .replace(/^\[object (.+)\]$/, '$1')
    .toLowerCase();
}

export function browserInfo() {
  const UserAgent = navigator.userAgent.toLowerCase();
  let browser = null;
  const browserArray = {
    IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
    Chrome:
      UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1, // Chrome浏览器
    Firefox: UserAgent.indexOf('firefox') > -1, // 火狐浏览器
    Opera: UserAgent.indexOf('opera') > -1, // Opera浏览器
    Safari:
      UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') === -1, // safari浏览器
    Edge: UserAgent.indexOf('edge') > -1, // Edge浏览器
    QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
    WeixinBrowser: /MicroMessenger/i.test(UserAgent), // 微信浏览器
  };
  for (let i in browserArray) {
    if (browserArray[i]) {
      browser = i;
    }
  }
  return browser;
}

export function getOperationSys() {
  let OS = '';
  let OSArray = {};
  const UserAgent = navigator.userAgent.toLowerCase();
  OSArray.Windows =
    navigator.platform === 'Win32' || navigator.platform === 'Windows';
  OSArray.Mac =
    navigator.platform === 'Mac68K' ||
    navigator.platform === 'MacPPC' ||
    navigator.platform === 'Macintosh' ||
    navigator.platform === 'MacIntel';
  OSArray.iphone = UserAgent.indexOf('iPhone') > -1;
  OSArray.ipod = UserAgent.indexOf('iPod') > -1;
  OSArray.ipad = UserAgent.indexOf('iPad') > -1;
  OSArray.Android = UserAgent.indexOf('Android') > -1;
  for (let i in OSArray) {
    if (OSArray[i]) {
      OS = i;
    }
  }
  return OS;
}

/**
 * 根据day获取星期
 * @export
 * @param {*} day 星期数
 * @param {*} type 类型(默认0) 0:周日 1:星期天 2:Sunday 3:Sun
 * @returns
 */
export function getWeek(day, type) {
  var weekday = [
    ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],

    ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],

    ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
  ];
  if (!type) {
    type = 0;
  }

  return weekday[type][day];
}

/**
 * 获取显示日期，格式为：2015年3月14日（周六）10:00-11:30
 *
 * @export
 * @param {*} StartTime 开始时间
 * @param {*} EndTime   结束时间
 * @param {*} ishasWeek 是否显示星期 默认显示
 * @returns
 */
export function showDateTime(StartTime, EndTime, ishasWeek) {
  if (
    StartTime === '' ||
    EndTime === '' ||
    StartTime === null ||
    EndTime === null
  ) {
    return '';
  }

  let SatrtTimeTemp = '',
    startYear,
    startMonth,
    startDay,
    startWeek,
    startTiming,
    endYear,
    endMonth,
    endDay,
    endWeek,
    endTiming;
  //获取开始年份
  startYear = new Date(StartTime).getFullYear();
  //获取开始月份
  startMonth = new Date(StartTime).getMonth() + 1;
  //获取开始日期
  startDay = new Date(StartTime).getDate();
  //获取星期
  startWeek = ishasWeek ? `（${getWeek(new Date(StartTime).getDay())}）` : '';
  //获取时间
  startTiming = StartTime.split(' ')[1] ? StartTime.split(' ')[1] : '';
  //获取截止年份
  endYear = new Date(EndTime).getFullYear();
  //获取截止月份
  endMonth = new Date(EndTime).getMonth() + 1;
  //获取截止日期
  endDay = new Date(EndTime).getDate();
  // 获取截至星期
  endWeek = ishasWeek ? `（${getWeek(new Date(EndTime).getDay())}）` : '';
  //获取截至时间
  endTiming = EndTime.split(' ')[1] ? EndTime.split(' ')[1] : '';

  SatrtTimeTemp += `${startYear}年${startMonth}月${startDay}日${startWeek}${startTiming}`;

  if (!(StartTime.split(' ')[0] === EndTime.split(' ')[0] && !startTiming)) {
    SatrtTimeTemp += '~';
  }

  if (startYear !== endYear) {
    SatrtTimeTemp += `${endYear}年${endMonth}月${endDay}日${endWeek}${endTiming}`;
  } else {
    if (startMonth !== endMonth) {
      SatrtTimeTemp += `${endMonth}月${endDay}日${endWeek}${endTiming}`;
    } else {
      if (startDay !== endDay) {
        SatrtTimeTemp += `${endDay}日${endWeek}${endTiming}`;
      } else {
        SatrtTimeTemp += `${endTiming}`;
      }
    }
  }
  return SatrtTimeTemp;
}

//活动所属栏目用于路由跳转,参数是栏目名称，栏目数组records和上级栏目编号
export function getChannelNo(name, records, channel) {
  let len = records.length;
  let subChannels;
  let channelNo;
  for (let i = 0; i < len; i++) {
    if (records[i].channelNo === channel) {
      subChannels = records[i].subChannels;
    }
  }
  if (subChannels) {
    let len1 = subChannels.length;
    for (let j = 0; j < len1; j++) {
      if (subChannels[j].channelName === name) {
        channelNo = subChannels[j].channelNo;
      }
    }
    return channelNo;
  } else {
    return;
  }
}

//活动下面的标题
export function getChannelName(channelNo, records, channel) {
  let len = records.length;
  let subChannels;
  let channelName;
  for (let i = 0; i < len; i++) {
    if (records[i].channelNo === channel) {
      subChannels = records[i].subChannels;
    }
  }
  if (!channelNo) {
    return subChannels;
  }
  const len1 = subChannels ? subChannels.length : 0;
  for (let j = 0; j < len1; j++) {
    if (subChannels[j].channelNo === channelNo) {
      channelName = subChannels[j].channelName;
    }
  }
  return channelName;
}

//将天气信息转化为图标
export function getWeathertoIcon(weather) {
  var iconinfo = [
    { name: '晴', icon: 'qing' },
    { name: '多云', icon: 'duoyun' },
    { name: '阴', icon: 'duoyun' },
    { name: '雾', icon: 'wu' },
    { name: '小雨', icon: 'xiaoyu' },
    { name: '中雨', icon: 'zhongyu' },
    { name: '阵雨', icon: 'zhenyu' },
    { name: '大雨', icon: 'dayu' },
    { name: '冻雨', icon: 'dongyu' },
    { name: '雷阵雨', icon: 'leizhenyu' },
    { name: '暴雨', icon: 'baoyu' },
    { name: '大暴雨到特大暴雨', icon: 'dabaoyudaotedabaoyu' },
    { name: '特大暴雨', icon: 'tedabaoyu' },
    { name: '小雪', icon: 'xiaoxue' },
    { name: '小到中雪', icon: 'xiaodaozhongxue' },
    { name: '中雪', icon: 'zhongxue' },
    { name: '中到大雪', icon: 'zhongdaodaxue' },
    { name: '大雪', icon: 'daxue' },
    { name: '暴雪', icon: 'baoxue' },
    { name: '沙尘暴', icon: 'shachenbao' },
  ];
  var iconarr = iconinfo.filter(item => {
    return item.name === weather;
  });
  if (iconarr.length > 0) {
    return 'icon' + iconarr[0].icon;
  } else {
    return 'iconqing';
  }
}

/**
 * author yangming
 * @param 将带标签的html内容转换成string字符串
 * @param {string} content : 需要处理的带<>的字符串
 * @param {number} num : 需要截取的字符串长度（默认为空）
 * @param return 截取之后的字符串
 * @param createTime:2019/3/4
 *
 * @param isEn : 判断是否为英文段落截取
 */
export function EnglishCut(content, num) {
  // 处理文本中的HTML标签
  // const s = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  // s为空字符串
  if (content === '') return '';
  // 判断是否为英文段落截取
  // if (isEn) {
  const str1 = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  if (str1.length < num) return str1;
  // 截取最后一个空格前的段落（段尾保留完整的英文单词）
  const text = str1.substr(0, Number(num));
  const n = text.lastIndexOf(' ');
  return `${text.substr(0, n)}...`;
  // }

  // const t = content.replace(/ /g, '');

  // if (t.length < num) {
  //   //如果文字数量少于num，则直接返回
  //   return `${t}`;
  // } else {
  //   // 需要截断文本长度
  //   if (num > 0) return `${t.substr(0, Number(num))}...`;
  //   return `${t}...`;
  // }
}
/**
 * @param 获取日期中的月和日
 * @param {string} date 日期 2019-05-20
 */
export function getDateofMonthandDay(date) {
  const arr = date && date.split('-');

  return { m: arr[1], d: arr[2] };
}

/**
 * @public
 * @description 将年份转换为对应的值
 * @param {string} key - 键
 * @returns {any} value - 结果（无值时为null）
 */
export function getYearList() {
  const date = new Date();
  const firstYear = date.getFullYear();
  let year = firstYear;
  const arr = [];

  arr.push({ lable: year, value: year });

  while (firstYear - year < 5) {
    year--;
    firstYear - year !== 5
      ? arr.push({ lable: year, value: year })
      : arr.push({ lable: year + '及以前', value: year + 'before' });
  }
  return arr;
}
export function handleYear(year, isEnd) {
  const start = !isEnd ? year + '-01-01' : '2010-01-01';
  const end = year + '-12-31';
  return start + '#' + end;
}

export function changeSize(path, width) {
  // THUMB
  let pa = path.split('/');
  pa.splice(-1, 0, 'THUMB');
  let imgpath = pa.pop().split('.');
  imgpath.splice(1, 0, `_${width}@x.`);
  pa.push(imgpath.join(''));
  return pa.join('/');
}
