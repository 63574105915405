import { getFirstImg, showDateTime } from '../utils';
const IMG_PREURL = window.FILE_PREFIX;
/**
 * 格式化二级栏目
 *
 * @param {*} i
 * @returns
 */
function mapFunc(i) {
  if (i.isVisible) {
    return {
      id: i.channelId,
      channelName: i.channelName,
      channelNo: i.channelNo.toLowerCase(),
      linkUrl: i.linkUrl,
      parentChannelNo: i.parentChannelNo.toLowerCase(),
      isHref: i.linkUrl.indexOf('http://') > -1,
      isSearch: i.linkUrl.indexOf('?') > -1,
      isInnerJump: i.linkUrl.indexOf('#') > -1,
      description: i.description,
      bannerimgSrc: encodeURI(
        i.bannerPhotoPath ? IMG_PREURL + i.bannerPhotoPath : '',
      ),
      subChannels:
        i.subChannel &&
        i.subChannel.map(sub => {
          return {
            id: sub.channelId,
            channelName: sub.channelName,
            channelNo: sub.channelNo.toLowerCase(),
            linkUrl: i.linkUrl,
            parentChannelNo: i.parentChannelNo.toLowerCase(),
            isHref: i.linkUrl.indexOf('http://') > -1,
            isSearch: i.linkUrl.indexOf('?') > -1,
            isInnerJump: i.linkUrl.indexOf('#') > -1,
            description: i.description,
            bannerimgSrc: encodeURI(
              i.bannerPhotoPath ? IMG_PREURL + i.bannerPhotoPath : '',
            ),
            subChannels:
              sub.subChannel &&
              sub.subChannel.map(sub => {
                return {
                  id: sub.channelId,
                  channelName: sub.channelName,
                  channelNo: sub.channelNo.toLowerCase(),
                  linkUrl: i.linkUrl,
                  parentChannelNo: i.parentChannelNo.toLowerCase(),
                  isHref: i.linkUrl.indexOf('http://') > -1,
                  isSearch: i.linkUrl.indexOf('?') > -1,
                  isInnerJump: i.linkUrl.indexOf('#') > -1,
                  description: i.description,
                  bannerimgSrc: encodeURI(
                    i.bannerPhotoPath ? IMG_PREURL + i.bannerPhotoPath : '',
                  ),
                };
              }),
          };
        }),
    };
  }
  return false;
}

/**
 * 生成 key栏目 value信息
 *
 * @param {*} list 一级列表
 * @returns
 */
function AllChannelFilter(list) {
  let c = [];
  c = list.reduce((a, b) => {
    const p = {
      channelName: b.channelName,
      channelNo: b.channelNo,
      id: b.id,
      parentChannelNo: b.parentChannelNo,
      pathname: `/${b.channelNo}`,
      firstChannel: b.subChannels[0] ? b.subChannels[0].channelNo : '',
      subChannels: b.subChannels,
    };
    if (p.parentChannelNo) a[b.channelNo] = p;

    b.subChannels.forEach(c => {
      const s = {
        channelName: c.channelName,
        channelNo: c.channelNo,
        id: c.id,
        parentChannelNo: p.channelNo,
        parentChannelName: p.channelName,
        pathname: `/${b.channelNo}/${c.channelNo}`,
      };
      if (c.subChannels) {
        c.subChannels.forEach(d => {
          const s1 = {
            channelName: d.channelName,
            channelNo: d.channelNo,
            id: c.id,
            parentChannelNo: p.channelNo,
            parentChannelName: p.channelName,
            pathname: `/${b.channelNo}/${d.channelNo}`,
          };
          a[d.channelNo] = s1;
        });
      }
      a[c.channelNo] = s;
    });
    return a;
  }, {});
  return c;
}

/**
 * 格式化栏目
 *
 * @export
 * @param {*} data 元数据
 * @returns
 */
export function ChannelFilter(data) {
  let list = [];
  data.map(item => {
    let subChannels =
      item.subChannel === null ? [] : item.subChannel.map(mapFunc);

    subChannels = subChannels.filter(d => d);
    if (item.isVisible) {
      list.push({
        id: item.channelId,
        channelName: item.channelName,
        channelNo: item.channelNo.toLowerCase(),
        linkUrl: item.linkUrl,
        parentChannelNo: item.parentChannelNo.toLowerCase(),
        subChannels,
        isHref: item.linkUrl && item.linkUrl.indexOf('http://') > -1,
        isSearch: item.linkUrl && item.linkUrl.indexOf('?') > -1,
        isHome: item.channelNo === 'Home',
        bannerimgSrc: encodeURI(
          item.bannerPhotoPath ? IMG_PREURL + item.bannerPhotoPath : '',
        ),
      });
    }
    return null;
  });
  return { list: list, channelList: AllChannelFilter(list) };
}

/**
 * 格式化列表
 *
 * @export
 * @param {*} data 元数据
 * @returns
 */
export function ListFilter(data) {
  return data.map(DetailFilter);
}

/**
 * 格式化详情
 *
 * @export
 * @param {*} item
 */
export function DetailFilter(item) {
  return {
    id: item.archivesId, // 文章id
    channelNo: item.channelNo, // 栏目编号
    channelName: item.channelName, // 栏目名称
    groupId: item.groupId,
    title: item.title, // 标题
    subTitle: item.subTitle, // 副标题
    flag: item.flag,
    author: item.extension1,
    publishDate: item.publishDate.split(' ')[0],
    parentChannelNo: item.parentChannelNo, // 父栏目
    // imgSrc: encodeURI(
    //   item.thumbImg
    //     ? IMG_PREURL + item.thumbImg
    //     : getFirstImg(item.content) || '',
    // ), // 缩略图，默认为'' 字符串作为 URI 进行编码
    imgSrc: item.thumbImg
      ? IMG_PREURL + item.thumbImg
      : getFirstImg(item.content) || '',
    appimgSrc: item.thumbAppImg ? IMG_PREURL + item.thumbAppImg : '', // app缩略图，默认为'' 字符串作为 URI 进行编码
    abstract: item.description, // 摘要内容
    content: item.content, // 详情
    source: item.source || '', // 文章来源
    linkUrl: item.linkUrl || '', // 自定义链接,
    visitCount: item.visitCount,
  };
}

/**
 * 格式化临展列表
 *
 * @export
 * @param {*} data
 */
export function ExhListFilter(data) {
  return data.map(ExhDetailFilter);
}

/**
 * 格式化临展详情
 *
 * @export
 * @param {*} data
 */
export function ExhDetailFilter(item) {
  return {
    id: item.exhibitionTempId,
    groupId: item.groupId,
    title: item.tempName,
    content: item.description,
    startTime: item.startTime.split(' ')[0],
    endTime: item.endTime.split(' ')[0],
    exhDate: showDateTime(
      item.startTime.split(' ')[0],
      item.endTime.split(' ')[0],
    ),
    place: item.tempPlace,
    linkUrl: item.linkUrl,
    imgSrc: encodeURI(
      item.thumb
        ? IMG_PREURL + item.thumb
        : '' || getFirstImg(item.description),
    ),
    appimgSrc: encodeURI(item.appThumb ? IMG_PREURL + item.appThumb : ''),
    mainOrganize: item.mainOrganize, //主办单位
    doOrganize: item.doOrganize, //承办单位
    supportOrganize: item.supportOrganize, //协办单位
    type: item.type,
  };
}

/**
 * 格式化常展列表
 *
 * @export
 * @param {*} data
 */
export function BaseListFilter(data) {
  return data.map(BaseDetailFilter);
}

/**
 * 格式化常展详情
 *
 * @export
 * @param {*} data
 */
export function BaseDetailFilter(item) {
  return {
    id: item.exhibitionBaseId,
    title: item.baseName,
    content: item.description,
    groupId: item.groupId,
    startTime: item.startTime && item.startTime.split(' ')[0],
    endTime: item.endTime && item.endTime.split(' ')[0],
    exhDate:
      item.startTime && item.endTime
        ? showDateTime(item.startTime.split(' ')[0], item.endTime.split(' ')[0])
        : '常设展览',
    place: item.basePlace,
    linkUrl: item.linkUrl && item.linkUrl,
    imgSrc: encodeURI(
      item.thumb
        ? IMG_PREURL + item.thumb
        : '' || getFirstImg(item.description),
    ),
    appimgSrc: encodeURI(item.appThumb ? IMG_PREURL + item.appThumb : ''),
  };
}

/**
 * 格式化文件列表
 *
 * @export
 * @param {*} data
 */
export function FileFilter(data) {
  return data.map(item => {
    return {
      id: item.fileInfoId,
      relateId: item.objectId,
      title: item.fileOldname.split('.')[0], //文件名称
      content: item.description,
      type: item.fileSuffix, //文件类型
      filePath: encodeURI(
        item.filePath ? IMG_PREURL + item.filePath + item.fileName : '',
      ),
    };
  });
}

/**
 * 格式化藏品列表
 *
 * @export
 * @param {*} data
 */
export function ColListFilter(data) {
  return data.map(item => {
    return {
      id: item.exhibitId,
      title: item.exhibitName,
      type: item.exhibitType,
      modelUrl: item.modelUrl,
      level: item.exhibitLevel,
      age: item.age,
      size: item.size,
      ageDetail: item.ageDetail,
      imgSrc: encodeURI(item.thumb ? IMG_PREURL + item.thumb : ''),
      appSrc: encodeURI(item.appThumb ? IMG_PREURL + item.appThumb : ''),
    };
  });
}

/**
 * 格式化藏品详情
 *
 * @export
 * @param {*} data
 */
export function ColDetailFilter(item) {
  return {
    id: item.exhibitId,
    title: item.exhibitName,
    type: item.exhibitType,
    modelUrl: item.modelUrl,
    level: item.exhibitLevel,
    age: item.age,
    size: item.size,
    content: item.description,
    explanationWord: item.explanationWord,
    count: item.clickCount,
    groupId: item.groupId,
    ageDetail: item.ageDetail,
    imgSrc: encodeURI(
      item.thumb
        ? IMG_PREURL + item.thumb
        : '' || getFirstImg(item.description),
    ),
    appimgSrc: encodeURI(item.appThumb ? IMG_PREURL + item.appThumb : ''),
  };
}

/**
 * 格式化全文搜索列表
 *
 * @export
 * @param {*} data
 */
export function SearchListFilter(data) {
  return data.map(item => {
    return {
      id: item.id, // 文章id
      channelNo: item.channelNo, // 栏目编号
      title: item.title, // 标题
      imgSrc: encodeURI(
        item.thumbImg
          ? IMG_PREURL + item.thumbImg
          : getFirstImg(item.content) || '',
      ), // 缩略图，默认为'' 字符串作为 URI 进行编码
      appimgSrc: encodeURI(
        item.thumbAppImg ? IMG_PREURL + item.thumbAppImg : '',
      ), // app缩略图，默认为'' 字符串作为 URI 进行编码
      abstract: item.description, // 摘要内容
      content: item.content, // 详情
      linkUrl: item.linkUrl || '', // 自定义链接,
    };
  });
}

export function SearchCollectionFilter(data) {
  return data.map(item => {
    return {
      id: item.exhibitId,
      title: item.exhibitName,
      imgSrc: encodeURI(item.thumb ? IMG_PREURL + item.thumb : ''),
      type: item.exhibitType,
      age: item.age,
    };
  });
}
