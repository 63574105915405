import React, { Component } from 'react';
import { SQueryOne, SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import { getColDetail } from '../../../../../mpa/dataProvider';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';
import Look from '../../../../../assets/images/desktop/common/enlarge.png';
import DetailShare from '../../../common/detailShare';
import { deleteHtmlTag, changeSize } from '../../../../../utils';
import Slider from 'react-slick';
import $ from 'jquery';
class ColDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {
        // rows: 4,
        slidesToShow: 4,
        // slidesPerRow: 4,
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToScroll: 4,
      },
      imgIndex: 0,
    };
  }

  changeImg = (i, small) => {
    this.setState({
      imgIndex: i,
    });
    $(this.img1).attr('src', small);
    $('#photoviewer img').each((index, item) => {
      if (index !== i) {
        $(item).css('display', 'none');
      } else {
        $(item).css('display', 'block');
      }
    });
  };

  render() {
    const id = this.props.location.pathname.split('/')[3];
    const { settings } = this.state;
    return (
      <div className="collectiondetail">
        <SQueryOne dataProvider={getColDetail()} options={id}>
          {props => {
            const item = props.data;
            const _url = window.location.href;
            const groupId = item.groupId;
            const arr = [];
            arr.push(item);
            return (
              <div className="topbox">
                <div className="img c imgstyle use-img ">
                  <img
                    style={{ objectFit: 'contain' }}
                    src={item.imgSrc ? item.imgSrc : null}
                    alt={item.title ? item.title : null}
                    ref={img1 => (this.img1 = img1)}
                  />
                </div>
                <div className="cont">
                  <h3>{item.title}</h3>
                  <div className="about">
                    <div className="viewed">
                      <i></i>
                      <span>查看（{item.count}）</span>
                    </div>
                    <DetailShare
                      url={_url}
                      title={item.title}
                      description={deleteHtmlTag(item.content)}
                      source={'武汉博物馆'}
                      img={item.imgSrc ? item.imgSrc : null}
                    />
                  </div>
                  <div className="intro">
                    <p>年代：{item.ageDetail ? item.ageDetail : item.age}</p>
                    <p>{item.size ? `尺寸：${item.size}` : ''}</p>
                    <p>类别：{item.type}</p>
                  </div>
                  <div
                    className="abstract"
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  ></div>
                </div>
                {groupId ? (
                  <>
                    <SQueryList
                      options={{
                        id: groupId,
                        code: 'CMS_EXHIBIT_INFO_DISPLAY',
                        pageSize: '0',
                      }}
                      type="QUERY_FIELDLIST"
                    >
                      {props => {
                        return (
                          <>
                            {props.records.length !== 0 ? (
                              props.records.map((ite, ind) => {
                                arr.push(ite);
                                if (ind === props.records.length - 1) {
                                  return (
                                    <>
                                      <IMG arr={arr}></IMG>
                                      <div className="colimg-listbox">
                                        <Slider {...settings}>
                                          {arr.map((c, i) => {
                                            return (
                                              <div
                                                className={`owl-item`}
                                                key={c.id}
                                              >
                                                <div
                                                  className={`imgstyle imgfull ${
                                                    this.state.imgIndex === i
                                                      ? 'active'
                                                      : ''
                                                  }`}
                                                  onClick={() => {
                                                    this.changeImg(
                                                      i,
                                                      c.imgSrc
                                                        ? c.imgSrc
                                                        : changeSize(
                                                            c.filePath,
                                                            600,
                                                          ),
                                                      c.appimgSrc
                                                        ? c.appimgSrc
                                                        : c.imgSrc
                                                        ? c.imgSrc
                                                        : c.filePath,
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      c.imgSrc
                                                        ? c.imgSrc
                                                        : changeSize(
                                                            c.filePath,
                                                            300,
                                                          )
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </Slider>
                                      </div>
                                    </>
                                  );
                                }
                                return null;
                              })
                            ) : (
                              <IMG arr={arr}></IMG>
                            )}
                          </>
                        );
                      }}
                    </SQueryList>
                  </>
                ) : null}
              </div>
            );
          }}
        </SQueryOne>
      </div>
    );
  }
}
class IMG extends Component {
  viewer = null;
  componentDidMount() {
    if (true) {
      this.viewer = new Viewer(document.getElementById('photoviewer'), {
        inline: false,
        url: 'data-original',
        title: false,
        navbar: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.viewer && this.viewer.destroy();
      this.viewer = new Viewer(document.getElementById('photoviewer'), {
        inline: false,
        url: 'data-original',
        title: false,
      });
    }
  }
  componentWillUnmount() {
    this.viewer && this.viewer.destroy();
  }
  render() {
    return (
      <div id="photoviewer" className="enlarge">
        {this.props.arr.map((item, index) => {
          return (
            <img
              src={Look}
              data-original={
                item.appimgSrc
                  ? item.appimgSrc
                  : item.imgSrc
                  ? item.imgSrc
                  : item.filePath
              }
              key={index}
              alt=""
            ></img>
          );
        })}
        {/* <img
              src={Look}
              data-original={
                item.appimgSrc
                  ? item.appimgSrc
                  : item.imgSrc
                  ? item.imgSrc
                  : null
              }
              ref={img2 => (this.img2 = img2)}
              alt=""
            ></img> */}
      </div>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(ColDetail);
