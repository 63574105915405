import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
class InnerBanner extends PureComponent {
  isActive(pathname, channelNo) {
    return (
      pathname.split('/').indexOf(channelNo) > -1 &&
      pathname.split('/').length < 3
    );
  }
  render() {
    const { routes } = this.props;
    const pathname = this.props.location.pathname;
    return (
      <div className="inner-banner">
        {routes &&
          routes.map(item =>
            this.isActive(pathname, item.channelNo) ? (
              item.bannerimgSrc ? (
                <img
                  src={item.bannerimgSrc}
                  alt={item.channelName}
                  key={item.id}
                />
              ) : (
                ''
              )
            ) : (
              ''
            ),
          )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  routes: state.toproutes.records,
});

export default withRouter(connect(mapStateToProps)(InnerBanner));
