import CryptoJS from 'crypto-js';

function Encrypt(key) {
  // const randomSix = (~~(Math.random() * 1000000)).toString();
  const r = Math.random();
  const randomSix = ('' + r).split('.')[1].slice(0, 6);
  const date = Date.parse(new Date()).toString();
  const text = randomSix + date;
  const encrypt = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(key), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  //挖坑
  return encrypt.toString();
}

//正式
export const id = 'cff7f597e3c14974856354466a4b79da';
//测试
// export const id = 'e664c9e97a70404ea39188fae2458ff9';

export default res => {
  //正式
  res.headers.token = Encrypt('ADD56A84C29B9A03BA3FF3608DBB2042');
  res.headers.appId = 'cff7f597e3c14974856354466a4b79da';
  //测试
  //   res.headers.token = Encrypt('7BD27DD4C0D92ACF9402A8C3C96A1A14');
  //   res.headers.appId = '9d2f1161f5904356ad1ae9f6fcfa7846';
  return res;
};
