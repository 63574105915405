import React, { Component } from 'react';
import { getInitPage, parseSearch } from '@/utils/cc';
export default (WrappedComponent, pageSize = 10) => {
  return class extends Component {
    state = {
      options: {
        pageSize,
        currentPage: getInitPage(parseSearch(this.props.location.search)),
      },
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
      // 首次跳转至该路由时 第一次更新的prevProps 没有page
      const prevPage = Number(
        parseSearch(prevProps.location.search).get('page') || 1,
      );
      const nextPage = Number(
        parseSearch(this.props.location.search).get('page') || 1,
      );
      //  如果点击后退按钮  则会触发history变化 通过这里触发UI更新
      if (prevPage !== nextPage) {
        this.setState(prevState => ({
          options: { ...prevState.options, currentPage: nextPage },
        }));
      }
    }
    onPageChange = page => {
      const { pathname, search } = this.props.location;

      const s = new URLSearchParams(search);
      s.set('page', page);
      this.props.history.push({
        pathname: pathname,
        search: `?${s.toString()}`,
      });
      //   prevState 好啊  不然连续state合并会出问题
      this.setState(prevState => ({
        options: { ...prevState.options, currentPage: page },
      }));
    };
    render() {
      const { options } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          pagerOption={options}
          onPageChange={this.onPageChange}
        ></WrappedComponent>
      );
    }
  };
};
