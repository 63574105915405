//  object-fit,object-position
//  https://caniuse.com/#search=object-fit
//  https://github.com/bfred-it/object-fit-images/
import objectFitImages from 'object-fit-images';
document.addEventListener('DOMContentLoaded', objectFitImages, false);

// all es6
// eslint-disable-next-line import/first
import '@babel/polyfill';
require('intersection-observer');
