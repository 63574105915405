import React, { Component } from 'react';
import { SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import TempExhListUI from './TempExhListUI';
import Pager from '@mpfe/pager';
import { getYearList } from '@/utils';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { parseSearch } from '@/utils/cc';
class TempExhList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cheaked: 'all',
      option: {
        exhtype: 'cms0701',
        startTime: undefined,
      },
    };
  }
  // changeToALl() {
  //     this.setState({
  //       option: {...this.state.option,startTime:undefined },
  //       cheaked: 'all',
  //     });
  //     this.props.onPageChange(1);
  //   }
  // handleClick(year) {
  //     const start = handleYear(year.value, year.lable !== year.value);
  //     this.setState({
  //       option: {...this.state.option, startTime: start },
  //       cheaked: year.value,
  //     });
  //     this.props.onPageChange(1);
  //   }
  render() {
    const firsturl =
      (this.props.location && this.props.location.pathname.split('/')[1]) ||
      'home';
    const secondurl =
      this.props.location && this.props.location.pathname.split('/')[2];
    const { pagerOption, onPageChange } = this.props;
    const choseYear = parseSearch(this.props.location.search).get('year'); //选择年份
    const startTime = choseYear
      ? choseYear.indexOf('before') > -1
        ? `1970-01-01#${choseYear.replace('before', '')}-12-31`
        : `${choseYear}-01-01#${choseYear}-12-31`
      : '';
    const options_tempexhibition = {
      ...pagerOption,
      ...this.state.option,
      startTime: startTime,
    };
    console.log(options_tempexhibition);
    const options_specialexhibition = {
      ...pagerOption,
      exhtype: 'cms0702',
    };
    const date = getYearList();
    return (
      <>
        <div className="main-title">
          <h3>
            {this.props.routes.routesMap[secondurl] &&
              this.props.routes.routesMap[secondurl].channelName}
          </h3>
        </div>
        {secondurl === 'tempexhibition' ? (
          <div className="timesearch">
            <ul>
              <li>
                <Link
                  to={{
                    pathname: `/${firsturl}/${secondurl}`,
                  }}
                  className={classnames('switch-all', {
                    active: startTime === '',
                  })}
                >
                  全部
                </Link>
              </li>
              {date.map(item => {
                return (
                  <li key={item.value}>
                    <Link
                      className={classnames('switch-item', {
                        active: parseInt(item.value) === parseInt(choseYear),
                      })}
                      to={{
                        pathname: `/${firsturl}/${secondurl}`,
                        search: `?year=${item.value}`,
                      }}
                    >
                      {item.lable}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ''
        )}

        <SQueryList
          type="QUERY_TEMP"
          options={
            secondurl === 'tempexhibition'
              ? options_tempexhibition
              : options_specialexhibition
          }
        >
          {props => {
            return (
              <>
                <TempExhListUI
                  data={props.records}
                  firstmenu={firsturl}
                  secmenu={secondurl}
                />
                {/* <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page =>
                    props.fetchHandle({
                      currentPage: page,
                      pageSize:
                        secondurl === 'tempexhibition'
                          ? this.options_tempexhibition.pageSize
                          : this.options_specialexhibition.pageSize,
                      exhtype:
                        secondurl === 'tempexhibition'
                          ? this.options_tempexhibition.exhtype
                          : this.options_specialexhibition.exhtype,
                    })
                  }
                ></Pager> */}
                <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  forcePage={pagerOption.currentPage}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page => {
                    onPageChange(page);
                  }}
                ></Pager>
              </>
            );
          }}
        </SQueryList>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(TempExhList);
