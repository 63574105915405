import React, { Component } from 'react';
import { SQueryOne, SQueryList } from '@mpfe/cskit';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LookBigImg from '../../../common/LookBigImg/index';
class TempExhDetail extends Component {
  render() {
    const id = this.props.match.params.resource;
    return (
      <>
        <div className="tempexhdetail">
          <SQueryOne options={{ id: id }} type="QUERY_TEMPDET">
            {props => {
              const item = props.data;
              const groupId = item.groupId;
              return (
                <>
                  <div className="detailshort">
                    <div className="topbox">
                      <div className="imgstyle">
                        <div
                          className="img"
                          style={{
                            backgroundImage: item.imgSrc
                              ? `url(${item.imgSrc})`
                              : null,
                          }}
                        />
                      </div>
                      <div className="cont">
                        <h3>{item.title}</h3>
                        <div className="title">展览信息</div>
                        <p>展览时间：{item.exhDate}</p>
                        <p>展览地点：{item.place}</p>
                        {item.mainOrganize ? (
                          <p>主办单位：{item.mainOrganize}</p>
                        ) : (
                          ''
                        )}
                        {item.doOrganize ? (
                          <p>承办单位：{item.doOrganize}</p>
                        ) : (
                          ''
                        )}
                        {item.supportOrganize ? (
                          <p>协办单位：{item.supportOrganize}</p>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className="title">展览简介</div>
                    <div className="maindetail">
                      <div
                        className="cont"
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      />
                    </div>
                  </div>
                  <div className="img-detail">
                    {groupId ? (
                      <>
                        <SQueryList
                          options={{
                            id: groupId,
                            code: 'CMS_EXHIBIT_TEMP_FILE',
                            pageSize: '0',
                          }}
                          type="QUERY_FIELDLIST"
                        >
                          {props => {
                            return (
                              <>
                                {props.records.length !== 0 ? (
                                  <>
                                    <div className="title">展厅风采</div>
                                    <LookBigImg
                                      data={props.records}
                                    ></LookBigImg>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          }}
                        </SQueryList>
                        <SQueryList
                          options={{
                            groupId,
                            pageSize: '0',
                          }}
                          type="QUERY_COLLIST_BYEXHID"
                        >
                          {props => {
                            return (
                              <>
                                {props.records.length !== 0 ? (
                                  <>
                                    <div className="title-img">相关藏品</div>
                                    <ul className="common-imglist">
                                      {props.records.map((item, key) => {
                                        return (
                                          <li key={key}>
                                            <Link
                                              to={`/exhibition/collect/${item.id}`}
                                            >
                                              <div className="imgstyle imgscale imgfull">
                                                <div
                                                  className="img"
                                                  style={{
                                                    backgroundImage: item.imgSrc
                                                      ? `url(${item.imgSrc})`
                                                      : null,
                                                  }}
                                                />
                                              </div>
                                              <div className="cont">
                                                <h3>{item.title}</h3>
                                                <p>{item.age}</p>
                                              </div>
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </>
                                ) : (
                                  ''
                                )}
                              </>
                            );
                          }}
                        </SQueryList>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              );
            }}
          </SQueryOne>
        </div>
      </>
    );
  }
}
export default connect(state => {
  return {
    routes: state.toproutes,
  };
})(TempExhDetail);
