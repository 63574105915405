import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { SQueryList } from '@mpfe/cskit';
import './index.scss';
import { connect } from 'react-redux';
import MyLink from '../../../components/MyLink';
class Education extends Component {
  state = {
    publiceduOptions: {
      channelNo: `publicedu`,
      pageSize: 5,
      currentPage: 1,
    },
    videoOptions: {
      channelNo: `video`,
      pageSize: 2,
      currentPage: 1,
    },
    volunteerOptions: {
      channelNo: `volunteer`,
      pageSize: 5,
      currentPage: 1,
    },
  };
  render() {
    const { routesMap } = this.props;
    const { publiceduOptions, videoOptions, volunteerOptions } = this.state;
    return (
      <>
        <div className="education-top">
          <div className="publicedu">
            <div className="main-title">
              <h3>
                {routesMap[publiceduOptions.channelNo] &&
                  routesMap[publiceduOptions.channelNo].channelName}
              </h3>
            </div>
            <div className="box">
              <SQueryList type="QUERY_LIST" options={publiceduOptions}>
                {props => (
                  <ul className="title-list">
                    {props.records.map(item => (
                      <li key={item.id}>
                        <MyLink
                          linkUrl={item.linkUrl}
                          to={`/education/publicedu/${item.id}`}
                        >
                          <p className="time">{item.publishDate}</p>
                          <h3 className="title">{item.title}</h3>
                        </MyLink>
                      </li>
                    ))}
                  </ul>
                )}
              </SQueryList>
              <Link to="/education/publicedu" className="viewmore">
                查看更多+
              </Link>
            </div>
          </div>
          <div className="video">
            <div className="main-title">
              <h3>
                {routesMap[videoOptions.channelNo] &&
                  routesMap[videoOptions.channelNo].channelName}
              </h3>
            </div>
            <div className="box">
              <SQueryList type="QUERY_LIST" options={videoOptions}>
                {props => (
                  <ul className="video-list">
                    {props.records.map(item => (
                      <li key={item.id}>
                        <MyLink
                          linkUrl={item.linkUrl}
                          to={`/education/video/${item.id}`}
                        >
                          <div className="imgstyle">
                            <div
                              className="img"
                              style={{
                                backgroundImage: item.imgSrc
                                  ? `url(${item.imgSrc})`
                                  : null,
                              }}
                            />
                          </div>
                          <h3 className="title">{item.title}</h3>
                        </MyLink>
                      </li>
                    ))}
                  </ul>
                )}
              </SQueryList>
              <Link to="/education/video" className="viewmore">
                查看更多+
              </Link>
            </div>
          </div>
        </div>
        <div className="volunteer">
          <div className="main-title">
            <h3>
              {routesMap[volunteerOptions.channelNo] &&
                routesMap[volunteerOptions.channelNo].channelName}
            </h3>
          </div>
          <div className="v-box">
            <SQueryList type="QUERY_LIST" options={volunteerOptions}>
              {props => (
                <ul className="title-list">
                  {props.records.map(item => (
                    <li key={item.id}>
                      <MyLink
                        linkUrl={item.linkUrl}
                        to={`/education/volunteer/${item.id}`}
                      >
                        <p className="time">{item.publishDate}</p>
                        <h3 className="title">{item.title}</h3>
                      </MyLink>
                    </li>
                  ))}
                </ul>
              )}
            </SQueryList>
            <Link to="/education/volunteer" className="viewmore">
              查看更多+
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default connect(state => {
  return {
    routesMap: state.toproutes.routesMap,
  };
})(Education);
