import React, { Component } from 'react';
import './Index.scss';
import { SQueryList } from '@mpfe/cskit';
// import { HtmltoStr } from '@/utils';
import { Link } from 'react-router-dom';
// import list from '@/assets/images/desktop/collection/list-1.jpg';
import Pager from '@mpfe/pager';

export default class ImageListbBig extends Component {
  render() {
    const { activeItem, secmenu, firstmenu } = this.props;
    const channelNo = window.location.pathname.split('/')[2];
    const option = {
      channelNo: '',
      currentPage: 1,
      exhibitName: '',
      pageSize: 8,
      exhibitLevel: 'CMS0900',
    };
    // const imgstyle =
    //   channelNo === 'calligraphy' || channelNo === 'painting' ? 'cover' : '';
    return (
      <div>
        <SQueryList
          options={channelNo === 'treasure' ? option : this.props.options}
          type="QUERY_COL"
        >
          {props => {
            return (
              <>
                <ul className="common-imglist" id="photoviewer">
                  {props.records.map(item => {
                    return (
                      <li key={item.id}>
                        <Link
                          to={{
                            pathname: `/${firstmenu}/${secmenu}/${item.id}`,
                            state: {
                              id: item.id,
                            },
                          }}
                        >
                          <div className="imgstyle imgfull imgscale">
                            <div
                              className={`img`}
                              style={{
                                backgroundImage: item.imgSrc
                                  ? `url(${item.imgSrc})`
                                  : null,
                              }}
                            ></div>
                          </div>
                          <div className="cont">
                            <h3>{item.title}</h3>
                            {!activeItem ? (
                              <p className="list-year">
                                &nbsp;
                                {item.ageDetail ? item.ageDetail : item.age}
                                &nbsp;
                              </p>
                            ) : null}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                <Pager
                  containerClassName="mypager"
                  pagerClassName="sw-pages"
                  pageCount={props.pageCount}
                  forcePage={this.props.pagerOption.currentPage}
                  hrefCreator={page => {
                    return `?page=${page}`;
                  }}
                  onPageChange={page => {
                    this.props.onPageChange(page);
                  }}
                ></Pager>
              </>
            );
          }}
        </SQueryList>
      </div>
    );
  }
}
