/* global BMap */
import React from 'react';
// import BMap from 'BMap';
import './index.scss';

export default class BaiduMap extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.map = null;
  }
  componentDidMount() {
    // 初始化地图

    this.map = new BMap.Map('mapcontainer', {
      enableMapClick: false,
    });
    this.map.enableScrollWheelZoom(); // 启用滚轮放大缩小，默认禁用
    this.map.enableContinuousZoom(); // 启用地图惯性拖拽，默认禁用
    // 添加控件
    const zoomCtrl = new BMap.NavigationControl();
    this.map.addControl(zoomCtrl);

    // 张家港中心点 112.653088, 28.041717
    // 112.670088, 28.048017;
    const point = new BMap.Point(114.263081, 30.617735);
    // const point1 = new BMap.Point(112.67, 28.048);
    const point2 = new BMap.Point(114.263081, 30.617735);
    const marker = new BMap.Marker(point);
    // 缩放地图

    // this.map.centerAndZoom(point1, 16);
    // this.map.setViewport([point, point1]);
    // this.map.centerAndZoom(point1, 16);
    // this.map.centerAndZoom(point1, 16);
    // this.map.setCenter(point2);
    // this.map.setZoom(16);
    let sContent = `<h3>武汉博物馆</h3>
        <p>武汉市江汉区青年路373号</p>`;
    let infoWindow = new BMap.InfoWindow(sContent); // 创建信息窗口对象
    // 不延迟，点会漂移
    // this.map.addEventListener('resize', () => {
    //     this.map.centerAndZoom(point2, 16);
    //     this.map.addOverlay(marker);

    //     marker.openInfoWindow(infoWindow);
    // });
    setTimeout(() => {
      this.map.centerAndZoom(point2, 16);
      this.map.addOverlay(marker);

      marker.openInfoWindow(infoWindow);
    }, 1000);

    marker.addEventListener('click', function() {
      this.openInfoWindow(infoWindow);
    });
  }
  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }
  render() {
    return (
      <div className="mapbox-pc">
        <div ref={this.container} id="mapcontainer" className="map" />
      </div>
    );
  }
}
