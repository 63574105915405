import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';

export default class Conbination extends Component {
  render() {
    return (
      <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="1s">
        <section className="index-combination">
          <ul className="list">
            <li className="item spzy">
              <Link to={'/education/video'}>
                <div className="bg" />
              </Link>
            </li>
            <li className="item xscb">
              <Link to={'/research/scipublish'}>
                <div className="bg" />
              </Link>
            </li>
            <li className="item cpyj">
              <Link to={'/research/colresearch'}>
                <div className="bg" />
              </Link>
            </li>
            <li className="item wwdc">
              <Link to={'/collection'}>
                <div className="bg" />
              </Link>
            </li>
          </ul>
        </section>
      </ReactWOW>
    );
  }
}
